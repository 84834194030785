import Header from 'components/modules/LandingPage/Header'
import ContactPerson from 'components/modules/LandingPage/ContactPerson'
import Meta from 'components/modules/Meta'
import theme from 'lib/constants/theme'
import KununuImage from 'assets/homepage/kununu.png'
import AntonioImage from 'assets/homepage/antonio.png'
import StatisticsGrid from 'components/modules/LandingPage/StatisticsGrid'
import InformationSection from 'components/modules/LandingPage/InformationSection'
import { URLs } from 'lib/constants/URLs'
import OurValuesSection from 'components/modules/LandingPage/OurValuesSection'
import OurStorySection from 'components/modules/LandingPage/OurStorySection'
import Founders from 'components/modules/LandingPage/Founders'
import HeroBackground from 'assets/homepage/hero.webp'

const Home = () => {
    const statisticsItems = [
        {
            title: '2.6 M+',
            subtitle: 'Users',
        },
        {
            title: '65',
            subtitle: 'Employees',
        },
        {
            title: '2',
            subtitle: 'Office buildings',
        },
        {
            title: '16',
            subtitle: 'Nationalities',
        },
    ]

    const contactPersonDetails = {
        headingText: 'Any questions left?',
        image: AntonioImage,
        name: 'Antonio Hoppe',
        email: 'people@knowunity.com',
        employeeTitle: 'Senior People & Culture Manager',
        buttonLink: 'mailto:people@knowunity.com',
    }
    return (
        <div>
            <Meta title="Jobs in Knowunity" description="Learn more about knowunity and our working conditions!" />
            <Header
                title="Join us in bringing the future of education to everyone"
                text="Join us in building the global platform for millions of students. Diverse and challenging projects await your responsibility from day one."
                buttonText="Open Positions"
                buttonTarget={URLs.CareersWebsite}
                buttonColor={theme.colors.flamingoPink}
                backgroundColor={theme.colors.deepSeaBlue}
                fullWidth
                image={{
                    src: HeroBackground,
                    height: 0,
                    width: 0,
                    placeholder: undefined,
                }}
            />
            <StatisticsGrid
                items={statisticsItems}
                image={{
                    src: KununuImage,
                    height: 80,
                    width: 200,
                    placeholder: undefined,
                }}
                textColor={theme.colors.white}
                onClickTarget={URLs.Kununu}
                backgroundColor={theme.colors.deepSeaBlue}
            />
            <OurStorySection />
            <InformationSection
                sectionTitle="Our Mission"
                title="We want to build the global platform for millions of students"
                subtitle="to provide every student with access to high quality school education."
                text="Our approach of bringing the best content to every student for free and connecting students on one digital platform to help out each other in a safe and secure environment is what makes us unique. We want to give students around the globe a voice for digital, social learning and we are still in the early days of our ride."
                buttonText="Open Positions"
                buttonTarget={URLs.CareersWebsite}
            />
            <OurValuesSection />
            <InformationSection
                title="We believe in the power of education."
                subtitle="That's why we founded Knowunity, originally out of our own problems in school."
                text="At Knowunity, we want to overcome the inequality as we sincerely believe each and every student must have the same opportunity to access high-quality school education. Education has the power to transform our lives, more likely to change our world."
                quoteCredential="- The Founders"
                buttonText="Open Positions"
                buttonTarget={URLs.CareersWebsite}
                addQuoteIcon
            />
            <Founders />
            <ContactPerson detail={contactPersonDetails} backgroundColor={theme.colors.blueIce} />
        </div>
    )
}

export default Home
