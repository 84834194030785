import React from 'react'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { Body1, ButtonText, Headline2, Headline3, Headline4 } from 'components/elements/Text'
import YellowCircle from 'assets/homepage/yellow-circle.webp'
import ApplicationIcon from 'assets/application-process/application.png'
import InterviewIcon from 'assets/application-process/interview.png'
import TeamIcon from 'assets/application-process/team.png'
import TestIcon from 'assets/application-process/test.png'
import VideoInterviewIcon from 'assets/application-process/video.png'
import Arrow from 'assets/icons/arrow.svg'
import Stack from 'components/elements/Stack'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import DownloadButton from 'components/elements/DownloadBanner'
import { URLs } from 'lib/constants/URLs'
import DynamicButton from 'components/elements/DynamicButton'
import routes from 'lib/constants/routes'
import { FlipCard } from 'components/elements/FlipCard'

const ArrowIcon = styled.img<{ degrees: number }>`
    display: flex;
    height: 80px;
    width: 80px;
    justify-self: center;
    align-self: center;
    position: relative;
    transform: ${({ degrees }) => `rotate(${degrees}deg)`};
    @media (max-width: 1080px) {
        display: none;
    }
`

const Container = styled.section`
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: ${theme.colors.deepSeaBlue};
    position: relative;
    display: grid;
    padding: 50px 75px 150px 75px;
    overflow: hidden;
    .subtitle {
        padding: 0 30%;
    }
    .circle {
        position: absolute;
        left: 0;
        top: 200px;
    }
    .bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        width: 100%;
        padding: 0 25%;
        .links {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
    }
    @media (max-width: 1080px) {
        .subtitle {
            padding: 0%;
        }

        && .links {
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }
    @media (max-width: 750px) {
        padding: 10%;
        .circle {
            display: none;
        }
        .bottom {
            padding: 0;
        }
    }
`

const ValuesWrapper = styled.div`
    padding: 75px 0px;
    display: grid;
    grid-template-columns: 430px 100px 430px;
    place-content: center;
    gap: 30px;

    @media (max-width: 1080px) {
        grid-template-columns: 1fr;
        .empty {
            display: none;
        }
    }
`

const InformationWrapper = styled.div`
    display: grid;
    height: 350px;
    width: 430px;
    gap: 30px;
    h3,
    .body,
    a {
        color: ${theme.colors.white};
    }
    a {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
    }
    .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
    }
    @media (max-width: 1080px) {
        justify-self: center;
        width: 100%;
        padding: 5%;
        h3,
        p {
            text-align: center;
        }
        .buttons {
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }
    @media (max-width: 750px) {
        width: 100%;
    }
`

enum ItemTypes {
    FlipCard = 'flipcard',
    Empty = 'empty',
    Arrow = 'arrow',
}

const ApplicationProcessList = () => {
    const md = useMedia(deviceSize.md)
    const lg = useMedia(deviceSize.lg)

    const applicationList = [
        {
            title: 'Application',
            icon: ApplicationIcon,
            text: 'Apply to your desired role and familiarize yourself with our product and mission. Download the app and give it a go.',
            type: ItemTypes.FlipCard,
            align: 'right',
            id: 1,
        },
        {
            src: Arrow,
            type: ItemTypes.Arrow,
            rotate: 0,
        },
        {
            title: 'Interview',
            icon: InterviewIcon,
            text: 'Have a first Chat with our talent team to get to know each other. This is usually a phone call of about 30 min.',
            type: ItemTypes.FlipCard,
            align: 'left',
            id: 2,
        },
        {
            type: ItemTypes.Empty,
        },
        {
            type: ItemTypes.Empty,
        },
        {
            src: Arrow,
            type: ItemTypes.Arrow,
            rotate: 90,
        },
        {
            title: 'Video Interview',
            icon: VideoInterviewIcon,
            text: 'We will discuss the take home test followed by Q&A and you’ll get to meet our colleagues from cross-functional teams.',
            type: ItemTypes.FlipCard,
            align: 'right',
            id: 4,
        },
        {
            src: Arrow,
            type: ItemTypes.Arrow,
            rotate: 180,
        },
        {
            title: 'Take home test',
            icon: TestIcon,
            text: 'Depending on the role, you’ll be required to complete technical challenge, or a take home exercise.',
            type: ItemTypes.FlipCard,
            align: 'left',
            id: 3,
        },
        {
            src: Arrow,
            type: ItemTypes.Arrow,
            rotate: 90,
        },
        {
            type: ItemTypes.Empty,
        },
        {
            type: ItemTypes.Empty,
        },
        {
            title: 'Meet the Team',
            icon: TeamIcon,
            text: 'Designed for you to have the chance to meet the wider team you’ll be working with. We are keen to get to know you as a person.',
            type: ItemTypes.FlipCard,
            align: 'right',
            id: 5,
        },
        {
            type: ItemTypes.Empty,
        },
    ]

    const returnListItem = (item: typeof applicationList[number]) => {
        switch (item.type) {
            case ItemTypes.Empty:
                return <div className="empty" />
            case ItemTypes.Arrow:
                return <ArrowIcon src={Arrow} alt="arrow" degrees={item.rotate ?? 0} />
            case ItemTypes.FlipCard:
                return (
                    <FlipCard key={`application-process-${item.id}`} align={item.align as 'left' | 'right'}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <Stack gutter={md ? 50 : 0}>
                                    <img src={item.icon} alt={`${item.title} application icon"`} />
                                    <Headline2
                                        color={theme.colors.sunlightYellow}
                                        marginBottom={md ? 50 : 75}
                                    >{`0${item.id}`}</Headline2>
                                    <div>
                                        <Headline4 color={theme.colors.accentBlue} marginBottom={md ? 15 : 25}>
                                            {item.title}
                                        </Headline4>
                                        <Body1 color={theme.colors.accentBlue} fontWeigth={600}>
                                            {item.text}
                                        </Body1>
                                    </div>
                                </Stack>
                            </div>
                            <div className="flip-card-back">
                                <Stack gutter={85}>
                                    <Headline2 color="rgba(255, 255, 255, 0.2)">{`0${item.id}`}</Headline2>
                                    <Headline4 color="rgba(255, 255, 255, 0.6)">{item.title}</Headline4>
                                </Stack>
                            </div>
                        </div>
                    </FlipCard>
                )
        }
    }

    const filterMobileApplicationList = () =>
        lg ? applicationList.filter((x) => !!x.id).sort((a, b) => a.id! - b.id!) : applicationList

    return (
        <Container>
            <img src={YellowCircle} alt="background circle" className="circle" />
            <Headline2 color={theme.colors.white} textAlign="center">
                Application process
            </Headline2>
            <Body1 color={theme.colors.white} textAlign="center" className="subtitle">
                Are you interested in one of the job openings? Have a look at our 5 application steps.
            </Body1>
            <ValuesWrapper>
                {filterMobileApplicationList().map((applicationProgressItem) => returnListItem(applicationProgressItem))}
                <InformationWrapper>
                    <Headline3>Get to know us and our product</Headline3>
                    <Body1 marginBottom={20} className="body">
                        Join us on our mission of bringing the future of education to everyone - we look forward to hearing
                        from you! Visit our{' '}
                        <a target="_blank" href={URLs.Knowunity} rel="noreferrer">
                            website
                        </a>{' '}
                        and check out our social media presence on{' '}
                        <a target="_blank" href={URLs.Instagram} rel="noreferrer">
                            Instagram
                        </a>
                        ,{' '}
                        <a target="_blank" href={URLs.TikTok} rel="noreferrer">
                            Tiktok
                        </a>{' '}
                        and{' '}
                        <a target="_blank" href={URLs.LinkedIn} rel="noreferrer">
                            Linkedin
                        </a>
                        .
                    </Body1>
                    <div className="buttons">
                        <DownloadButton type="ios" />
                        <DownloadButton type="android" />
                    </div>
                </InformationWrapper>
            </ValuesWrapper>
            <div className="bottom">
                <Body1 textAlign="center" color={theme.colors.white}>
                    Find out what you can expect when working at Knowunity
                </Body1>
                <div className="links">
                    <DynamicButton
                        href={routes.homepageRoute}
                        color={theme.colors.white}
                        borderRadius={theme.borderRadius.normal}
                    >
                        <ButtonText color={theme.colors.accentBlue}>Working at Knowunity</ButtonText>
                    </DynamicButton>
                    <DynamicButton href={routes.cultureRoute} type="white" borderRadius={theme.borderRadius.normal}>
                        <ButtonText color={theme.colors.white}>Culture &amp; Diversity</ButtonText>
                    </DynamicButton>
                </div>
            </div>
        </Container>
    )
}

export default ApplicationProcessList
