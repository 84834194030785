import theme from 'lib/constants/theme'
import styled from 'styled-components'

interface Props {
    readonly color?: string
    readonly marginBottom?: number
    readonly textAlign?: 'left' | 'right' | 'center'
    readonly fontWeigth?: number
    readonly isREM?: boolean
}

export const Headline1 = styled.h1<Props>`
    font-family: 'Poppins', sans-serif;
    font-size: 65px;
    font-weight: 900;
    line-height: 80px;
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Headline2 = styled.h2<Props>`
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 900};
    line-height: 64px;
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    @media (max-width: 1080px) {
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
    }
`

export const Headline3 = styled.h3<Props>`
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 700};
    line-height: 48px;
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    @media (max-width: 1080px) {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
    }
`

export const Headline4 = styled.h4<Props>`
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 700};
    line-height: 32px;
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    /* Reset */
    text-transform: none;

    @media (max-width: 1080px) {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }
`

export const Headline5 = styled.h5<Props>`
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 700};
    line-height: 30px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    /* Reset */
    text-transform: none;

    @media (max-width: 1080px) {
        font-size: 15px;
        line-height: 20px;
    }
`

export const Subtitle1 = styled.h5<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: ${(props) => props.fontWeigth ?? 700};
    line-height: 24px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Subtitle2 = styled.h6<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: ${(props) => props.fontWeigth ?? 700};
    line-height: 16px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Body1 = styled.p<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: ${(props) => props.fontWeigth ?? 600};
    line-height: 20px;
    color: ${(props) => props.color ?? theme.colors.body1Black};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Body2 = styled.p<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: ${(props) => props.fontWeigth ?? 600};
    line-height: 24px;
    color: ${(props) => props.color ?? theme.colors.body2Black};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Overline = styled.p<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    color: ${(props) => props.color ?? theme.colors.captionGray};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const ButtonText = styled.p<Props>`
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 700};
    line-height: 24px;
    color: ${(props) => props.color ?? theme.colors.captionGray};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const QuoteText = styled.p<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 600};
    line-height: 32px;
    font-style: italic;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const QuoteText2 = styled.p<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 600};
    line-height: 24px;
    font-style: italic;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Caption = styled.p<Props>`
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: ${({ fontWeigth }) => fontWeigth ?? 700};
    line-height: 16px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`
