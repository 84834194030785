import React, { useEffect } from 'react'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { Headline2, Headline3, QuoteText, QuoteText2 } from 'components/elements/Text'
import FlameIcon from 'assets/homepage/flame.png'
import RocketIcon from 'assets/homepage/rocket.png'
import LearningIcon from 'assets/homepage/learning.svg'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import useMedia from 'lib/hooks/useMedia'

const Container = styled.div`
    width: 100%;
    min-height: 800px;
    height: 1500px;
    background-color: ${theme.colors.blueIce};
    position: relative;
    display: grid;
    padding: 5% 0;
    .title {
        width: 100%;
        position: sticky;
    }
    @media (max-width: 1680px) {
        height: 2000px;
    }
    @media (max-width: 1440px) {
        height: 2500px;
    }

    @media (max-width: 1080px) {
        overflow: hidden;
        height: auto;
        grid-template-columns: 1fr;
        place-content: start center;
        padding-top: 0px;
    }
`

const ValuesWrapper = styled.div`
    padding: 10% 5% 0 5%;

    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 125px;

    will-change: transform;
    transform: translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;

    @media (max-width: 1250px) {
        grid-gap: 100px;
    }
    @media (max-width: 1080px) {
        grid-gap: 0px;
        grid-template-columns: 1fr;
    }
`

const MobileWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 25px 10%;
    margin-top: 50px;
`

const Card = styled.div<{ backgroundColor: string }>`
    display: grid;
    height: 290px;
    width: 600px;
    overflow: visible;
    background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.deepSeaBlue};
    border: 3px solid ${theme.colors.accentBlue};
    box-shadow: 4px 4px 0px ${theme.colors.accentBlue};
    border-radius: ${theme.borderRadius.huge};
    padding: 50px 50px 50px 70px;
    position: relative;
    h3 {
        position: absolute;
        top: -25px;
        left: 0;
        right: 0;
        text-shadow: 4px 2px 0px ${theme.colors.accentBlue};
    }
    .icon {
        position: absolute;
        left: -30px;
        top: -50px;
    }
    @media (max-width: 1080px) {
        height: auto;
        display: flex;
        width: 100%;
        flex-direction: column;
        place-content: start;
        grid-template-columns: 1fr;
        align-items: center;
        padding: 30px 20px;
        h3 {
            position: static;
            text-shadow: none;
        }
    }
`

const Camera = styled.div`
    @media (min-width: 1080px) {
        width: 100%;
        height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 200px;
        z-index: 50;
        overflow: hidden;
    }
    @media (max-height: 800px) {
        top: 125px;
    }
    overflow: auto;
`
interface Props {}

const OurValuesSection = (props: Props) => {
    const lg = useMedia(deviceSize.lg)
    const tablet = useMedia(deviceSize.tablet)
    const semifullWidth = useMedia(deviceSize.semiFullWidth)

    useEffect(() => {
        const background = document.getElementById('horizontal-values-scroll')

        const listenToScroll = () => {
            if (background?.style)
                background.style.transform = `translate3d(${
                    1570 - window.scrollY * 0.1 - window.innerWidth * 0.55 + 100 * (semifullWidth ? -0.5 : 1)
                }vw, 0px, 0px)`
        }

        window.addEventListener('scroll', listenToScroll)
        listenToScroll()
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [semifullWidth, tablet])

    const ourValuesData = [
        {
            title: 'PASSION',
            color: theme.colors.forestGreen,
            text: 'Passion is why we do what we do. We founded Knowunity with the passion of solving the problems we faced in school, and it is important to us to bring that passion into our work everyday.',
            image: FlameIcon,
        },
        {
            title: 'ENTREPRENEURSHIP',
            color: theme.colors.flamingoPink,
            text: 'Everyone is able to be their own entrepreneur and drive their own department forward with a great deal of responsibility and proactivity. We believe that this allows everyone to develop their potential in the best possible way.',
            image: RocketIcon,
        },
        {
            title: 'EDUCATION MATTERS',
            color: theme.colors.deepSeaBlue,
            text: 'Education is the key to anything in life. Each of us should have the chance to develop every day - and we want to give this chance to all students worldwide.',
            image: LearningIcon,
        },
    ]

    return (
        <Container>
            {lg ? (
                <MobileWrapper>
                    {ourValuesData.map((data, i) => (
                        <Card key={`advantage-${i}`} backgroundColor={data.color}>
                            <Headline3 color={theme.colors.white} marginBottom={20} textAlign="center">
                                {data.title}
                            </Headline3>
                            <QuoteText2 textAlign="center" color={theme.colors.white} fontWeigth={600}>
                                {data.text}
                            </QuoteText2>
                        </Card>
                    ))}
                </MobileWrapper>
            ) : (
                <Camera>
                    <Headline2 className="title" textAlign="center">
                        OUR VALUES
                    </Headline2>
                    <ValuesWrapper id="horizontal-values-scroll">
                        {ourValuesData.map((data, i) => (
                            <Card key={`advantage-${i}`} backgroundColor={data.color}>
                                <Headline3 color={theme.colors.white} marginBottom={10} textAlign="center">
                                    {data.title}
                                </Headline3>
                                <img src={data.image} alt={data.title} className="icon" />
                                <QuoteText textAlign="left" color={theme.colors.white} fontWeigth={600}>
                                    {data.text}
                                </QuoteText>
                            </Card>
                        ))}
                    </ValuesWrapper>
                </Camera>
            )}
        </Container>
    )
}

export default OurValuesSection
