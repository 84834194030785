export enum TeamNames {
    Founders = 'Founders',
    BusinessDevelopment = 'Business Development',
    Engineering = 'Engineering',
    HR = 'HR',
    Product = 'Product',
    Data = 'Data',
    Expansion = 'Expansion',
    SalesCustomerSupport = 'Sales & Customer Support',
    Marketing = 'Marketing',
    Growth = 'Growth',
    Finance = 'Finance',
}

export interface TeamMember {
    full_name: string
    image: {
        alt: string
        dimensions: { width: number; height: number }
        url: string
    }
    job_title: string
    team: TeamNames
}

export interface PrismicTeamMemberDoc {
    data: {
        member: TeamMember[]
    }
    type: 'team-member'
}
