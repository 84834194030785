import Header from 'components/modules/LandingPage/Header'
import Meta from 'components/modules/Meta'
import theme from 'lib/constants/theme'
import { URLs } from 'lib/constants/URLs'
import HeroBackground from 'assets/culture/culture-hero.webp'
import MobileHeroBackground from 'assets/culture/culture-hero.webp'
import BenefitsList from 'components/modules/LandingPage/BenefitsList'
import ApplyNowBanner from 'components/modules/LandingPage/ApplyNowBanner'
import ImageBackgroundSection from 'components/modules/LandingPage/ImageBackgroundSection'
import MissionBackgroundImage from 'assets/culture/mission-background.webp'
import MobileMissionBackgroundImage from 'assets/culture/mobile-mission.webp'
import TeamImagesSection from 'components/modules/LandingPage/TeamImagesSection'
import OurPolicy from 'components/modules/LandingPage/OurPolicy'
import MobileFAB from 'components/elements/MobileFAB'

const Culture = () => {
    return (
        <div>
            <Meta
                title="Culture in Knowunity"
                description="Learn more about the benefits that come with working in Knowunity!"
            />
            <MobileFAB href={URLs.CareersWebsite} />
            <Header
                title="Take a peek into Knowunity"
                text="First and foremost, we want you to feel happy, included and motivated, be healthy and  have the opportunity for personal development. In the following you can find our main benefits."
                buttonText="Apply now"
                buttonTarget={URLs.CareersWebsite}
                buttonColor={theme.colors.white}
                buttonTextColor={theme.colors.accentBlue}
                backgroundColor={theme.colors.deepSeaBlue}
                fullWidth
                mobileImageUrl={MobileHeroBackground}
                image={{
                    src: HeroBackground,
                    height: 0,
                    width: 0,
                    placeholder: undefined,
                }}
                addScrollAnimation
                hideBottomStatistics
            />
            <ImageBackgroundSection
                image={{
                    src: MissionBackgroundImage,
                    height: 500,
                    width: 100,
                    placeholder: undefined,
                }}
                fullWidth
                title="Our mission is to be the leading learning platform for students - worldwide"
                backgroundColor={theme.colors.blueIce}
                mobileImage={{
                    src: MobileMissionBackgroundImage,
                    height: 0,
                    width: 0,
                    placeholder: undefined,
                }}
            />
            <BenefitsList />
            <TeamImagesSection fullWidth />
            <OurPolicy />
            <ApplyNowBanner />
        </div>
    )
}

export default Culture
