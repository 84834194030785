import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from 'assets/icons/expand_arrow.svg'
import ExpandMoreWhiteIcon from 'assets/icons/expand_arrow_white.svg'
import { useToggleState } from 'lib/hooks/useToggleState'
import { Subtitle1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

interface AccordionProps {
    accentBlue?: boolean
    isExpanded?: boolean
    noPadding?: boolean
    hideBorder?: boolean
    isDarkTheme?: boolean
}

const Accordion = styled(MuiAccordion)<AccordionProps>`
    &.MuiAccordion-root {
        border: none;
        padding: ${({ noPadding }) => (!noPadding ? 10 : 0)}px;
        margin: 15px 10px;
        margin-bottom: ${({ isExpanded }) => (isExpanded ? 25 : 0)}px !important;
        background-color: transparent;
        transition: all 0.5s ease;
        &:not(:last-child) {
            border-bottom: 0;
        }
        &:before {
            display: none;
        }
        &.Mui-expanded {
            margin: 15px 0;
        }
        &.MuiAccordion-rounded {
            border-radius: ${theme.borderRadius.normal};
            border: ${({ isExpanded }) =>
                isExpanded ? `3px solid ${theme.colors.accentBlue}` : `1px solid ${theme.colors.grayBackground}`};
            ${({ accentBlue, isExpanded }) =>
                accentBlue && isExpanded
                    ? css`
                          box-shadow: 4px 4px 0px ${theme.colors.white};
                          margin: 15px 16.0987px 15px 0; // right margin - 11.0987 (box-shadow) + 5 = 16.0987[px]
                          background-color: ${theme.colors.white};
                          @media (max-width: 750px) {
                              margin: 15px 0;
                          }
                      `
                    : ''}
            ${({ isDarkTheme }) =>
                isDarkTheme
                    ? css`
                          border: none;
                      `
                    : ''}
        }
    }
`

const AccordionSummary = styled(MuiAccordionSummary)<AccordionSummaryProps & AccordionProps>`
    &.MuiAccordionSummary-root {
        margin: 0;
        margin-bottom: -1px;
        min-height: 56px;

        display: flex;
        justify-content: center;

        .main-text {
            width: 100%;
        }
        &.Mui-expanded {
            margin: 0;
        }
        @media (max-width: 900px) {
            min-height: 75px;
            .main-text {
                padding: 0 5%;
            }
        }
    }
`

const AccordionDetails = styled(MuiAccordionDetails)`
    &.MuiAccordionDetails-root {
        padding: 0 15% 20px 15%;
        @media (max-width: 900px) {
            padding: 0 5% 20px 5%;
        }
    }
`

const TextWrapper = styled.div<AccordionProps>`
    text-align: left !important;
    display: inline;
    padding: 0;
    p {
        color: ${({ isExpanded }) => (isExpanded ? theme.colors.accentBlue : theme.colors.mediumWhite)};
    }
`

interface Props extends AccordionProps {
    title: string
    text: JSX.Element
    children?: ReactNode
    accordionKey?: string
}

export default function TransparentAccordion({
    title,
    text,
    accentBlue,
    isDarkTheme,
    children,
    noPadding,
    hideBorder,
    accordionKey,
}: Props) {
    const [expanded, toggleExpanded] = useToggleState(false)

    return (
        <Accordion
            key={`collapsible-panel-${title}`}
            expanded={expanded}
            onChange={toggleExpanded}
            accentBlue={!!accentBlue}
            isExpanded={expanded}
            noPadding={!!noPadding}
            hideBorder={hideBorder}
            isDarkTheme={isDarkTheme}
        >
            <AccordionSummary
                expandIcon={<img src={expanded ? ExpandMoreIcon : ExpandMoreWhiteIcon} alt="expand icon" />}
                aria-controls={`collapsible-panel-summary-${title}`}
                id={`collapsible-panel-header-${title}`}
                accentBlue={!!accentBlue}
                isDarkTheme={isDarkTheme}
            >
                {accordionKey ? (
                    <Subtitle1 color={expanded ? theme.colors.sunlightYellow : '#FFFFFF33'}>{accordionKey}</Subtitle1>
                ) : null}
                <Subtitle1
                    color={expanded ? theme.colors.accentBlue : theme.colors.mediumWhite}
                    textAlign="center"
                    className="main-text"
                >
                    {title}
                </Subtitle1>
            </AccordionSummary>
            <AccordionDetails>
                <TextWrapper accentBlue={!!accentBlue} isExpanded={expanded}>
                    {text}
                </TextWrapper>
            </AccordionDetails>
            {children}
        </Accordion>
    )
}
