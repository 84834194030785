export enum URLs {
    CareersWebsite = 'https://careers.knowunity.de/jobs',
    Instagram = 'https://www.instagram.com/knowunity',
    TikTok = 'https://tiktok.com/@knowunity',
    LinkedIn = 'https://linkedin.com/company/knowunity',
    Kununu = 'https://www.kununu.com/de/knowunity1',
    Knowunity = 'https://knowunity.de',
}

export const appDownloadUrl = (type: 'app' | 'android' | 'ios', source: string, sourceCampaign: string, path?: string) =>
    `https://a.knowunity.de/${type}?source=${source}&campaign=${sourceCampaign}${path ? `&path=${path}` : ''}`
