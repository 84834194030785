import { useEffect } from 'react'
import DynamicButton from 'components/elements/DynamicButton'
import { Body1, ButtonText, Headline2 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { URLs } from 'lib/constants/URLs'
import styled from 'styled-components'
import TeamImage1 from 'assets/culture/team-1.webp'
import TeamImage2 from 'assets/culture/team-2.webp'
import TeamImage3 from 'assets/culture/team-3.webp'
import TeamImage4 from 'assets/culture/team-4.webp'
import TeamImage5 from 'assets/culture/team-5.webp'
import MobileTeamImage1 from 'assets/culture/mobile-team-1.webp'
import MobileTeamImage2 from 'assets/culture/mobile-team-2.webp'
import MobileTeamImage3 from 'assets/culture/mobile-team-3.webp'
import MobileTeamImage4 from 'assets/culture/mobile-team-4.webp'
import MobileTeamImage5 from 'assets/culture/mobile-team-5.webp'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'

const Container = styled.div<{ fullWidth?: boolean }>`
    min-height: calc(80vh + var(--navbar-height));
    height: ${({ fullWidth }) => (fullWidth ? '200vh' : 'auto')};
    background-color: ${theme.colors.blueIce};

    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: start;
    justify-content: center;
    padding: 0 5%;
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
    position: relative;
    .images {
        overflow: hidden;
    }
    .texts {
        width: 60%;
        position: -webkit-sticky;
        position: sticky;
        top: 30%;
        left: 0;
        display: grid;
        gap: 25px;
        padding: 50px 0;
    }
    @media (max-height: 800px) and (min-width: 1081px) {
        .texts {
            top: 25%;
        }
    }
    @media (max-width: 1080px) {
        padding: 0;
        grid-template-columns: 1fr;
        .texts {
            width: 100%;
            top: 70px;
            display: grid;
            gap: 10px;
            padding: 0px 50px;
            h2,
            p {
                text-align: center;
            }
            background-color: ${theme.colors.blueIce};
            z-index: ${theme.zIndex.topBanner};
        }
        height: 175vh;
    }
    @media (max-width: 900px) {
        .texts {
            top: 0;
            padding: 70px 50px 20px 50px;
        }
    }
`

const TeamPicture = styled.img<{ count: number }>`
    position: absolute;
    right: ${({ count }) => 200 * count + 25}px;
    @media (max-width: 1080px) {
        right: ${({ count }) => 100 * count + 25}px;
    }
`

interface Props {
    fullWidth?: boolean
}

const TeamImagesSection = ({ fullWidth }: Props) => {
    const lg = useMedia(deviceSize.lg)

    useEffect(() => {
        const image1 = document.getElementById('image-1')
        const image2 = document.getElementById('image-2')
        const image3 = document.getElementById('image-3')
        const image4 = document.getElementById('image-4')
        const image5 = document.getElementById('image-5')

        const listenToScroll = () => {
            if (image1?.style) image1.style.top = `${lg ? 500 : -1700 + window.scrollY * 0.55}px`
            if (image2?.style) image2.style.top = `${lg ? 700 : -1400 + window.scrollY * 0.55}px`
            if (image3?.style) image3.style.top = `${lg ? 900 : -1200 + window.scrollY * 0.51}px`
            if (image4?.style) image4.style.top = `${lg ? 1100 : -1150 + window.scrollY * 0.55}px`
            if (image5?.style) image5.style.top = `${lg ? 1300 : -800 + window.scrollY * 0.53}px`
        }

        window.addEventListener('scroll', listenToScroll)
        listenToScroll()
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [lg])

    return (
        <Container fullWidth={fullWidth}>
            <div className="texts">
                <Headline2>Your absolute dream job? Possible!</Headline2>
                <Body1 marginBottom={30} color={theme.colors.accentBlue}>
                    First and foremost, we want you to feel happy, included and motivated, be healthy and have the
                    opportunity for personal development. In the following you can find our main benefits.
                </Body1>
                {!lg ? (
                    <DynamicButton
                        href={URLs.CareersWebsite}
                        width={200}
                        height={60}
                        color={theme.colors.accentBlue}
                        borderRadius={theme.borderRadius.normal}
                    >
                        <ButtonText color={theme.colors.white}>Apply now</ButtonText>
                    </DynamicButton>
                ) : null}
            </div>
            <div className="images">
                <TeamPicture src={lg ? MobileTeamImage1 : TeamImage1} count={0.25} id="image-1" />
                <TeamPicture src={lg ? MobileTeamImage3 : TeamImage3} count={0.1} id="image-3" />
                <TeamPicture src={lg ? MobileTeamImage4 : TeamImage4} count={1.45} id="image-4" />
                <TeamPicture src={lg ? MobileTeamImage5 : TeamImage5} count={0} id="image-5" />
                <TeamPicture src={lg ? MobileTeamImage2 : TeamImage2} count={1.25} id="image-2" />
            </div>
        </Container>
    )
}

export default TeamImagesSection
