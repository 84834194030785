const colors = {
    knowunityBlue: '#65a0f1',
    knowunityPink: '#97348C',
    knowunityBlueDark: '#4990f3',
    accentBlue: '#2B3F5F',
    subtitleBlack: '#3D3E40',
    body1Black: '#616366',
    body2Black: '#919599',
    captionGray: '#C2C7CC',
    outlineGray: '#E3E6EB',
    buttonTextBlack: '#18191A',
    white: '#ffffff',
    black: '#000000',
    headlineDark: '#18191a',
    lightCaption: '#C8DFFF',
    caption: '#c2c7cc',
    errorRed: '#f6465b',
    accentOrange: '#FF9078',
    darkerAccentBlue: '#1C2735',
    backgroundColorGray: '#fafafa',
    lightCredit: '#FDF159',
    subjectGreen: '#0fd246',
    subjectBlue: '#1882FF',
    subjectYellow: '#FFA401',
    sunlightYellow: '#FFC30E',
    grayBackground: '#dfe2e7',
    accentRed: '#FF9078',
    deepSeaBlue: '#2A46BD',
    flamingoPink: '#F15599',
    forestGreen: '#1E8148',
    snowWhite: '#EFF3F9',
    blueIce: '#C8DFFF',
    lightAccentBlue: '#909bab',
    textError: '#E6423C',
    mediumWhite: '#FFFFFFD9',
}

const borderRadius = {
    smallest: '2px',
    small: '5px',
    large: '8px',
    card: '15px',
    normal: '15px',
    message: '20px',
    huge: '30px',
}

const zIndex = {
    cookieBanner: 100,
    navBarMobileExpanded: 95,
    modal: 90,
    tooltipText: 80,
    navBar: 70,
    navBarUserDropdown: 65,
    topBanner: 42,
    homepageBanner: 40,
    player: 40,
    playerIcons: 41,
    FAB: 50,
}

const lengths = {
    navbar: 70,
}

const theme = {
    colors,
    borderRadius,
    zIndex,
    lengths,
}

export default theme
