import styled from 'styled-components'
import YellowSun from 'assets/culture/yellow-sun.svg'
import MobileSun from 'assets/culture/mobile-sun.svg'
import { Body1, Headline2 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

const Container = styled.div`
    width: 100%;
    height: auto;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: ${theme.colors.deepSeaBlue};
    h2,
    p {
        color: ${theme.colors.white};
        text-align: center;
        width: 50%;
    }
    padding-bottom: 75px;
    @media (max-width: 1080px) {
        z-index: 1;
        justify-content: start;
        h2,
        p {
            width: 100%;
        }
        min-height: 800px;
        padding: 50px 25px;
    }
`

const Sun = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    @media (max-width: 1080px) {
        display: none;
    }
`

const MobileSunIcon = styled.img`
    position: absolute;
    right: 0;
    bottom: 25px;
    z-index: -1;
    @media (min-width: 1080px) {
        display: none;
    }
`

const OurPolicy = () => {
    return (
        <Container>
            <Sun src={YellowSun} alt="sun icon" />
            <MobileSunIcon src={MobileSun} alt="sun icon" />
            <Headline2>Diversity &amp; Inclusion</Headline2>
            <Body1>
                We believe in creating and securing an inclusive work culture where our highly diverse workforce can strive
                and feel empowered to reach their full potential.
            </Body1>
            <Body1>
                We cherish our honest and open feedback culture and show each other the respect that we expect for ourselves
                and thus create a warm and healthy working environment.
            </Body1>
        </Container>
    )
}

export default OurPolicy
