import theme from 'lib/constants/theme'
import styled, { css } from 'styled-components'
import FeatureImage from './FeatureImage'

const HeaderBackground = styled.div<{
    backgroundColor?: string
    fullWidth?: boolean
    addScrollAnimation?: boolean
    hideBottomStatistics?: boolean
}>`
    min-height: calc(80vh + var(--navbar-height));
    height: ${({ fullWidth, hideBottomStatistics }) => (fullWidth ? (hideBottomStatistics ? '100vh' : '80vh') : 'auto')};
    display: grid;
    align-items: center;

    background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.white};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    @media (max-height: 800px) {
        height: auto;
    }
    @media (max-width: 1080px) {
        height: auto;
        min-height: 450px;
        ${({ fullWidth }) =>
            fullWidth
                ? css`
                      background-image: none !important;
                  `
                : ''};
    }
    @keyframes animatedBackground {
        from {
            background-position: 0 0;
        }
        /*use negative width if you want it to flow right to left else and positive for left to right*/
        to {
            background-position: 0 -10000px;
        }
    }
    ${({ addScrollAnimation }) =>
        addScrollAnimation
            ? css`
                  background-repeat: repeat-y;
                  animation: animatedBackground 200s linear infinite;
              `
            : ''}
`

interface Props {
    title: string
    text: string
    buttonText: string
    buttonTarget: string
    buttonColor: string
    backgroundColor: string
    fullWidth?: boolean
    image: StaticImageData
    buttonTextColor?: string
    mobileImageUrl?: string
    addScrollAnimation?: boolean
    hideBottomStatistics?: boolean
    isWide?: boolean
}

const Header = ({
    title,
    text,
    buttonTarget,
    buttonText,
    buttonTextColor,
    buttonColor,
    backgroundColor,
    fullWidth,
    mobileImageUrl,
    image,
    addScrollAnimation,
    hideBottomStatistics,
    isWide,
}: Props) => {
    return (
        <HeaderBackground
            style={{
                backgroundImage: fullWidth ? `url(${image.src ?? ''})` : '',
            }}
            backgroundColor={backgroundColor}
            fullWidth={fullWidth}
            addScrollAnimation={addScrollAnimation}
            hideBottomStatistics={hideBottomStatistics}
        >
            <FeatureImage
                title={title}
                text={text}
                mobileImageUrl={mobileImageUrl}
                buttonText={buttonText}
                target={buttonTarget}
                backgroundColor={backgroundColor}
                primaryButtonColor={buttonColor}
                buttonTextColor={buttonTextColor}
                onButtonClick={() => {
                    /* empty */
                }}
                isWide={isWide}
                isHeader
                isDarkTheme
                useLandingPageHeaderFont
                addScrollAnimation={addScrollAnimation}
                image={
                    !fullWidth ? (
                        <img
                            src={image.src}
                            height={image.height}
                            width={image.width}
                            alt={image.placeholder ?? 'Header hero'}
                        />
                    ) : (
                        <></>
                    )
                }
            />
        </HeaderBackground>
    )
}

export default Header
