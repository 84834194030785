import { useEffect, useState } from 'react'

const useMedia = (query: string) => {
    const [matches, setMatches] = useState<boolean>(false)

    useEffect(() => {
        setMatches(window.matchMedia(query).matches)
        const media = window.matchMedia(query)
        if (media.matches !== matches) {
            setMatches(media.matches)
        }
        const listener = () => setMatches(media.matches)
        media.addListener(listener)
        return () => media.removeListener(listener)
    }, [matches, query])

    return matches
}

export default useMedia
