import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
    title: string | null
    description?: string
    noIndex?: boolean
    hrefLang?: boolean
    type?: 'article'
    imageUrl?: string
    canonical?: boolean
}

const Meta = (props: Props) => {
    const title = props.title ? `${props.title} - Knowunity` : 'Jobs in Knowunity'

    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:url" content={window.location.href} />
            {props.description ? <meta name="description" content={props.description} /> : null}
            {props.description ? <meta name="og:description" content={props.description} /> : null}
            <meta property="og:type" content={props.type ?? 'website'} />
            {props.noIndex && <meta name="robots" content="noindex" />}
            <meta
                property="og:image"
                content={props?.imageUrl ?? 'https://content.knowunity.de/static/website-preview-image.png'}
            />
        </Helmet>
    )
}

export default Meta
