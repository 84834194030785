import theme from 'lib/constants/theme'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Headline3, Subtitle1 } from './Text'

interface Props {
    title?: string
    subtitle?: string
    color?: string
    fullWidth?: boolean
    fullMobileHeight?: boolean
    children?: ReactNode
}

const Container = styled.div<Props>`
    height: ${({ fullWidth }) => (fullWidth ? '100vh' : 'auto')};
    min-height: 800px;
    width: 100%;
    background-color: ${({ color }) => color ?? theme.colors.white};
    background-repeat: no-repeat;
    object-fit: contain;
    overflow: hidden;
    background-position: center;
    @media (max-width: 750px) {
        height: ${({ fullMobileHeight }) => (fullMobileHeight ? '800px' : 'auto')};
        min-height: auto;
    }
    section {
        width: 92%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 0;
        @media (max-width: 1080px) {
            padding: 10px 0 50px 0;
        }
    }
`

const ComponentContainer = ({ title, subtitle, color, fullWidth, fullMobileHeight, children }: Props) => (
    <Container color={color} fullWidth={fullWidth} fullMobileHeight={fullMobileHeight}>
        <section>
            <Subtitle1 color={theme.colors.knowunityBlue} textAlign="center">
                {title}
            </Subtitle1>
            <Headline3 color={theme.colors.accentBlue} textAlign="center" marginBottom={50}>
                {subtitle}
            </Headline3>
            {children}
        </section>
    </Container>
)

export default ComponentContainer
