import styled from 'styled-components'
import HandIcon from 'assets/culture/hand.svg'
import { ButtonText, Headline2 } from 'components/elements/Text'
import { URLs } from 'lib/constants/URLs'
import DynamicButton from 'components/elements/DynamicButton'
import theme from 'lib/constants/theme'

const Container = styled.div`
    width: 100%;
    height: auto;
    min-height: 500px;
    display: grid;
    gap: 100px;
    place-content: center;
    padding: 50px 25px;
    position: relative;
    background-color: ${theme.colors.flamingoPink};
    a {
        display: flex;
        justify-content: center;
    }

    @media (max-height: 800px) and (min-width: 1081px) {
        min-height: 600px;
    }
    @media (max-width: 1080px) {
        min-height: 550px;
        place-content: start center;
        gap: 50px;
    }
`

const Hand = styled.img`
    position: absolute;
    left: 100px;
    bottom: 0;
    z-index: 0;
    @media (max-width: 1080px) {
        left: 0;
    }
    @media (max-width: 750px) {
        left: -100px;
    }
`

const ApplyNowBanner = () => {
    return (
        <Container>
            <Hand src={HandIcon} alt="hand icon" />
            <Headline2 textAlign="center" color={theme.colors.white}>
                What are you waiting for -
                <br />
                apply now!
            </Headline2>

            <DynamicButton
                width={250}
                href={URLs.CareersWebsite}
                color={theme.colors.white}
                borderRadius={theme.borderRadius.normal}
                type="white"
            >
                <ButtonText color={theme.colors.accentBlue}>Open Positions</ButtonText>
            </DynamicButton>
        </Container>
    )
}

export default ApplyNowBanner
