import { Body1, ButtonText, Headline1, Headline4 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import React from 'react'
import styled, { css } from 'styled-components'
import DynamicButton from 'components/elements/DynamicButton'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'

interface Props {
    title: string
    text: string
    image: JSX.Element
    buttonText: string
    onButtonClick?: () => void
    target: string
    reverse?: boolean
    isHeader?: boolean
    maxHeaderImageWidth?: number
    keepImageRatio?: boolean
    primaryButtonColor?: string
    buttonTextColor?: string
    backgroundColor?: string
    mobileImageUrl?: string
    fullWidthImage?: boolean
    useLandingPageHeaderFont?: boolean
    centerMobileImage?: boolean
    isDarkTheme?: boolean
    addScrollAnimation?: boolean
    isWide?: boolean
}

const Container = styled.div<{
    reverse?: boolean
    isHeader?: boolean
    alignBottom?: boolean
    transparentBackground?: boolean
    maxHeaderImageWidth?: number
    keepImageRatio?: boolean
    backgroundColor?: string
    fullWidthImage?: boolean
    centerMobileImage?: boolean
    addScrollAnimation?: boolean
    isWide?: boolean
}>`
    display: grid;
    min-height: ${({ isHeader }) => (isHeader ? '80vh' : '100%')};
    grid-template-columns: ${({ isWide }) => (isWide ? '2.5fr 1fr' : '1.5fr 1fr')};
    grid-gap: 30px;
    padding: 0 5%;
    align-items: center;
    position: relative;
    overflow: hidden;
    ${({ transparentBackground }) =>
        transparentBackground
            ? css`
                  background-color: transparent;
              `
            : ''}
    img {
        ${({ alignBottom }) =>
            alignBottom
                ? css`
                      position: absolute;
                      bottom: 0;
                  `
                : ''};
        height: auto;
        max-width: 750px;
        ${({ keepImageRatio, isHeader, maxHeaderImageWidth }) =>
            !keepImageRatio
                ? css`
                      height: auto;
                      width: ${isHeader ? maxHeaderImageWidth ?? '100%' : '550px'} !important;
                      max-width: 750px;
                  `
                : ''}
    }
    @media (max-height: 800px) and (min-width: 1081px) {
        min-height: 800px;
    }
    .image {
        display: flex;
        justify-content: center;
    }
    .mobile-image {
        display: none;
    }
    @media (max-width: 1080px) {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: ${({ reverse }) => (reverse ? 'column' : 'column-reverse')};
        padding: 25px;
        max-width: 100vw;

        .mobile-image {
            display: block;
            width: 100vw;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: right;

            height: 450px;
            margin: 0px 0 -25px 0;
            ${({ addScrollAnimation }) =>
                addScrollAnimation
                    ? css`
                          background-repeat: repeat-y;
                          animation: animatedBackground 200s linear infinite;
                      `
                    : ''}
        }
    }
    @media (max-width: 576px) {
        .mobile-image {
            width: 900px;
            height: 400px;
        }
    }
`

const TextWrapper = styled.div<{ isDarkTheme?: boolean; useLandingPageHeaderFont?: boolean }>`
    display: grid;
    place-content: center center;
    padding-top: 25px;
    .subtitle {
        width: 50%;
    }

    & h1 {
        padding-right: 200px;
        font-weight: 700;
        color: ${({ isDarkTheme }) => (isDarkTheme ? theme.colors.white : theme.colors.accentBlue)};
        ${({ useLandingPageHeaderFont }) =>
            !useLandingPageHeaderFont
                ? css`
                      font-size: 40px;
                      line-height: 50px;
                      font-weight: 900;
                  `
                : ''}
        @media (max-width: 1080px) {
            font-size: 36px;
            padding: 0 5%;
            line-height: 48px;
            text-align: center;
        }
        @media (max-width: 750px) {
            text-align: center;
        }
    }
    @media (max-width: 1080px) {
        padding-top: 50px;
        p {
            margin: 0;
        }
        .subtitle {
            width: 100%;
            padding: 10px 10%;
            text-align: center;
        }
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: 10px;
    span {
        position: relative;
    }
    @media (max-width: 1080px) {
        span,
        div,
        a {
            width: 100%;
        }

        justify-content: center;
    }
`

const FeatureImage = ({
    title,
    text,
    image,
    buttonText,
    target,
    reverse,
    isHeader,
    onButtonClick,
    maxHeaderImageWidth,
    keepImageRatio,
    primaryButtonColor,
    backgroundColor,
    fullWidthImage,
    mobileImageUrl,
    useLandingPageHeaderFont,
    centerMobileImage,
    isDarkTheme,
    buttonTextColor,
    addScrollAnimation,
    isWide,
}: Props) => {
    const lg = useMedia(deviceSize.lg)

    return (
        <Container
            reverse={reverse ?? false}
            isHeader={isHeader ?? false}
            maxHeaderImageWidth={maxHeaderImageWidth}
            keepImageRatio={!!keepImageRatio}
            backgroundColor={backgroundColor}
            fullWidthImage={fullWidthImage}
            centerMobileImage={centerMobileImage}
            addScrollAnimation={addScrollAnimation}
            isWide={isWide}
        >
            {reverse && !fullWidthImage && !lg && <div>{image}</div>}
            {mobileImageUrl ? (
                <div
                    className="mobile-image"
                    style={{
                        backgroundImage: `url(${mobileImageUrl})`,
                    }}
                />
            ) : null}
            <TextWrapper useLandingPageHeaderFont={useLandingPageHeaderFont} isDarkTheme={isDarkTheme}>
                {isHeader ? (
                    <Headline1 marginBottom={lg ? 5 : 25}>{title}</Headline1>
                ) : (
                    <Headline4 color={theme.colors.mediumWhite} marginBottom={35}>
                        {title}
                    </Headline4>
                )}

                {text && (
                    <Body1 color={theme.colors.mediumWhite} marginBottom={50} className="subtitle">
                        {text}
                    </Body1>
                )}

                <ButtonWrapper>
                    <span onClick={onButtonClick ?? undefined}>
                        <DynamicButton
                            width={320}
                            height={60}
                            fullWidth
                            href={target}
                            borderRadius={theme.borderRadius.normal}
                            color={primaryButtonColor ?? theme.colors.knowunityBlue}
                        >
                            <ButtonText color={buttonTextColor ?? theme.colors.white} textAlign="center">
                                {buttonText}
                            </ButtonText>
                        </DynamicButton>
                    </span>
                </ButtonWrapper>
            </TextWrapper>
            {!reverse && !fullWidthImage && !lg && <div className="image">{image}</div>}
        </Container>
    )
}

export default FeatureImage
