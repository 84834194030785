import { deviceSize } from 'lib/constants/GridBreakpoints'
import theme from 'lib/constants/theme'
import useMedia from 'lib/hooks/useMedia'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DynamicButton from './DynamicButton'
import { ButtonText } from './Text'

const FABWrapper = styled.div<{ isScrolled?: boolean }>`
    position: fixed !important;

    width: 100%;
    a {
        width: 100%;
    }
    bottom: 25px;
    padding: 0 5%;
    z-index: ${theme.zIndex.FAB};
    transition: transform 0.5s;
    z-index: ${theme.zIndex.FAB};
    ${({ isScrolled }) => (!isScrolled ? `transform: translateY(calc(100px))` : '')};

    //display: ${({ isScrolled }) => (!isScrolled ? 'none' : 'block')};
    @media (min-width: 1081px) {
        display: none;
    }
`

interface Props {
    href: string
}

const MobileFAB = ({ href }: Props) => {
    const [isScrolledDown, setIsScrolledDown] = useState(false)
    const lg = useMedia(deviceSize.lg)

    useEffect(() => {
        const listenToScroll = () => {
            const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop
            setIsScrolledDown(scrollPosition > 300 ? true : false)
        }

        window.addEventListener('scroll', listenToScroll)
        listenToScroll()
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [])

    return (
        <>
            {lg ? (
                <FABWrapper isScrolled={isScrolledDown}>
                    <DynamicButton
                        href={href}
                        height={60}
                        color={theme.colors.white}
                        borderRadius={theme.borderRadius.normal}
                        fullWidth
                    >
                        <ButtonText color={theme.colors.accentBlue}>Apply now</ButtonText>
                    </DynamicButton>
                </FABWrapper>
            ) : null}
        </>
    )
}

export default MobileFAB
