import React from 'react'
import styled from 'styled-components'
import { Body1, ButtonText, Headline3, Headline4, Subtitle1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import MailIcon from 'assets/icons/mail.svg'
import { NewButtonA } from 'components/elements/Button'

interface Props {
    detail: DetailInterface
    backgroundColor?: string
}

interface DetailInterface {
    headingText: string
    image: StaticImageData | string
    name: string
    email: string
    buttonLink: string
    phoneNumber?: string
    buttonText?: string
    subtitle?: string
    employeeTitle?: string
}

const Container = styled.div<{ backgroundColor?: string }>`
    display: grid;
    min-height: 500px;
    align-items: center;
    @media (max-width: 750px) {
        height: auto;
    }
    background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.accentBlue};
    color: ${theme.colors.white};
    text-align: left;
    & section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .credential {
        width: 315px;
        display: grid;
        grid-template-columns: 1fr;
        place-content: start;
        grid-gap: 0;
        .contact {
            display: flex;
            gap: 10px;
        }
    }

    & .right {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 75px;
    }

    & img {
        max-width: 164px;
        border-radius: 100%;
        z-index: 2;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: fill;
    }

    & a,
    span {
        color: ${theme.colors.white};
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        text-align: left;
    }

    @media (max-width: 750px) {
        & section {
            grid-template-columns: 1fr;
        }

        & img {
            max-width: 50%;
        }

        & .right {
            place-items: center;
            grid-template-columns: 1fr;
            grid-gap: 45px;

            & div {
                text-align: left;
                display: grid;
                place-items: center;
            }
        }
        h3 {
            margin-bottom: 50px;
        }
    }
`

const ContactPerson = ({ detail, backgroundColor }: Props) => (
    <Container backgroundColor={backgroundColor}>
        <section>
            <Headline3 color={theme.colors.accentBlue} textAlign="center" marginBottom={110}>
                {detail.headingText}
            </Headline3>
            <div className="right">
                <img
                    height={164}
                    width={164}
                    src={typeof detail.image === 'string' ? detail.image : detail.image.src}
                    alt="Ihr Ansprechpartner bei Knowunity"
                />
                <div className="credential">
                    <Headline4 color={theme.colors.accentBlue}>{detail.name}</Headline4>
                    {detail.employeeTitle ? <Body1 color={theme.colors.accentBlue}>{detail.employeeTitle}</Body1> : null}
                    <br />

                    <a href={'mailto:' + detail.email} className="contact">
                        <img src={MailIcon} alt="Email icon" />
                        <Subtitle1 color={theme.colors.accentBlue}>{detail.email}</Subtitle1>
                    </a>
                    <br />
                    <NewButtonA
                        href={detail.buttonLink}
                        fullWidth
                        width={250}
                        height={50}
                        color={theme.colors.accentBlue}
                        borderRadius={theme.borderRadius.normal}
                    >
                        <ButtonText color={theme.colors.white}>{detail?.buttonText ?? 'Contact us'}</ButtonText>
                    </NewButtonA>
                </div>
            </div>
        </section>
    </Container>
)

export default ContactPerson
