import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from 'assets/icons/expand_arrow.svg'
import ExpandMoreBlueIcon from 'assets/icons/expand_arrow_blue.svg'
import { useToggleState } from 'lib/hooks/useToggleState'
import { Subtitle1 } from 'components/elements/Text'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import useMedia from 'lib/hooks/useMedia'
import theme from 'lib/constants/theme'

interface AccordionProps {
    accentBlue?: boolean
    isExpanded?: boolean
    noPadding?: boolean
    hideBorder?: boolean
    isDarkTheme?: boolean
}

const Accordion = styled(MuiAccordion)<AccordionProps>`
    &.MuiAccordion-root {
        border: none;
        padding: ${({ noPadding }) => (!noPadding ? 10 : 0)}px;
        margin: 15px 10px;
        margin-bottom: ${({ accentBlue, isExpanded }) => (accentBlue && isExpanded ? 25 : 0)}px !important;
        &:not(:last-child) {
            border-bottom: 0;
        }
        &:before {
            display: none;
        }
        &.Mui-expanded {
            margin: 15px 0;
        }
        &.MuiAccordion-rounded {
            border-radius: ${theme.borderRadius.normal};
            border: ${({ hideBorder }) =>
                !hideBorder ? `3px solid ${theme.colors.accentBlue}` : `1px solid ${theme.colors.grayBackground}`};
            ${({ accentBlue, isExpanded }) =>
                accentBlue && isExpanded
                    ? css`
                          box-shadow: 4px 4px 0px ${theme.colors.accentBlue};
                          margin: 15px 16.0987px 15px 0; // right margin - 11.0987 (box-shadow) + 5 = 16.0987[px]
                          @media (max-width: 750px) {
                              margin: 15px 0;
                          }
                      `
                    : ''}
            ${({ isDarkTheme }) =>
                isDarkTheme
                    ? css`
                          border: none;
                      `
                    : ''}
        }
        ${({ isDarkTheme }) =>
            isDarkTheme
                ? css`
                      background-color: rgba(255, 255, 255, 0.1);
                      border: none;
                  `
                : ''}
    }
`

const AccordionSummary = styled(MuiAccordionSummary)<AccordionSummaryProps & AccordionProps>`
    &.MuiAccordionSummary-root {
        margin: 0;
        margin-bottom: -1px;
        min-height: 56px;

        &.Mui-expanded {
            margin: 0;
        }
        svg {
            color: ${({ accentBlue }) => (accentBlue ? theme.colors.accentBlue : theme.colors.knowunityBlue)};
        }
    }
`

const AccordionDetails = styled(MuiAccordionDetails)`
    &.MuiAccordionDetails-root {
    }
`

const TextWrapper = styled.div<AccordionProps>`
    text-align: left !important;
    display: inline;
    padding: 0;

    a,
    b {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: ${({ accentBlue }) => (accentBlue ? theme.colors.accentBlue : theme.colors.knowunityBlue)};
    }
`

interface Props extends AccordionProps {
    title: string
    text: JSX.Element
    children?: ReactNode
}

export default function CollabsibleAccordion({
    title,
    text,
    accentBlue,
    isDarkTheme,
    children,
    noPadding,
    hideBorder,
}: Props) {
    const [expanded, toggleExpanded] = useToggleState(false)
    const md = useMedia(deviceSize.md)

    const returnTitleColor = () => {
        switch (true) {
            case accentBlue:
                return md ? theme.colors.subtitleBlack : theme.colors.accentBlue
            case isDarkTheme:
                return theme.colors.white
            default:
                return theme.colors.body1Black
        }
    }
    return (
        <Accordion
            key={`collapsible-panel-${title}`}
            expanded={expanded}
            onChange={toggleExpanded}
            accentBlue={!!accentBlue}
            isExpanded={expanded}
            noPadding={!!noPadding}
            hideBorder={hideBorder}
            isDarkTheme={isDarkTheme}
        >
            <AccordionSummary
                expandIcon={<img src={accentBlue ? ExpandMoreIcon : ExpandMoreBlueIcon} alt="expand icon" />}
                aria-controls={`collapsible-panel-summary-${title}`}
                id={`collapsible-panel-header-${title}`}
                accentBlue={!!accentBlue}
                isDarkTheme={isDarkTheme}
            >
                <Subtitle1 color={returnTitleColor()}>{title}</Subtitle1>
            </AccordionSummary>
            <AccordionDetails>
                <TextWrapper accentBlue={!!accentBlue}>{text}</TextWrapper>
            </AccordionDetails>
            {children}
        </Accordion>
    )
}
