import React, { useEffect, useMemo, useState } from 'react'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import routes, { navigationItems } from 'lib/constants/routes'
import Logo from 'assets/logo.svg'
import { ButtonText, Subtitle1 } from 'components/elements/Text'
import { NavLink } from 'react-router-dom'
import { URLs } from 'lib/constants/URLs'
import DynamicButton from 'components/elements/DynamicButton'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import usePrevious from 'lib/hooks/usePrevious'
import { useToggleState } from 'lib/hooks/useToggleState'
import MobileNavbarMenu from './MobileNavbarMenu'
import MenuIcon from 'assets/icons/menu.svg'

const Container = styled.nav<{ hideOnScrollDownMobile?: boolean }>`
    position: fixed;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 5%;
    top: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(50px);
    z-index: ${theme.zIndex.navBar};
    align-items: center;
    height: ${theme.lengths.navbar}px;

    a {
        color: ${theme.colors.white};
    }
    li {
        list-style: none;
    }
    ul {
        gap: 50px;
        display: flex;
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
    .logo {
        display: flex;
        align-items: center;
        gap: 7px;
    }
    transition: transform 0.2s;
    @media (max-width: 900px) {
        .left {
            display: flex;
            gap: 10px;
        }
        border: 10 solid red;
        ${({ hideOnScrollDownMobile }) =>
            hideOnScrollDownMobile ? `transform: translateY(calc(-1 * ${theme.lengths.navbar}px))` : ''}
    }
`

const NavBar = () => {
    const [showMobileMenu, toggleMobileMenu] = useToggleState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const prevScrollPosition = usePrevious(scrollPosition)
    const md = useMedia(deviceSize.md)

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll)
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [])

    const listenToScroll = () => {
        setScrollPosition(document.body.scrollTop || document.documentElement.scrollTop)
    }

    const hideOnMobile = useMemo(
        () => !!(prevScrollPosition && scrollPosition > prevScrollPosition),
        [prevScrollPosition, scrollPosition]
    )

    return (
        <Container hideOnScrollDownMobile={hideOnMobile}>
            <div className="left">
                {md ? (
                    <img src={MenuIcon} onClick={toggleMobileMenu} className="mobile-expand-icon" alt="expand menu icon" />
                ) : null}
                <NavLink to={routes.homepageRoute}>
                    <div className="logo">
                        <img src={Logo} alt="Knowunity logo" />
                        <Subtitle1 color={theme.colors.white}>Knowunity</Subtitle1>
                    </div>
                </NavLink>
            </div>

            <div className="right">
                {!md ? (
                    <>
                        {navigationItems.map((item) => (
                            <NavLink to={item.route} key={`nav-link-${item.route.replace('/', '')}`}>
                                <Subtitle1 color={theme.colors.white}>{item.label}</Subtitle1>
                            </NavLink>
                        ))}
                    </>
                ) : null}
                <DynamicButton
                    href={URLs.CareersWebsite}
                    width={md ? 150 : 200}
                    height={40}
                    color={theme.colors.white}
                    borderRadius={theme.borderRadius.normal}
                >
                    <ButtonText color={theme.colors.accentBlue}>Open Positions</ButtonText>
                </DynamicButton>
            </div>
            <MobileNavbarMenu open={showMobileMenu} handleClose={toggleMobileMenu} />
        </Container>
    )
}

export default NavBar
