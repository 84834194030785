import styled from 'styled-components'
import BenedictImage from 'assets/homepage/benedict.webp'
import LucasImage from 'assets/homepage/lucas.webp'
import GregorImage from 'assets/homepage/gregor.webp'
import JulianImage from 'assets/homepage/julian.webp'
import YannikImage from 'assets/homepage/yannik.webp'
import ImageFilter from 'assets/homepage/image-filter.webp'
import LinkedInLogo from 'assets/icons/linkedin-white.svg'
import TriangleBlob from 'assets/homepage/triangle.svg'
import { Body1, ButtonText, Headline2, Headline5 } from 'components/elements/Text'
import { URLs } from 'lib/constants/URLs'
import DynamicButton from 'components/elements/DynamicButton'
import theme from 'lib/constants/theme'

const Container = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: grid;
    gap: 100px;
    place-content: center;
    padding: 50px 25px;
    position: relative;
    background-color: ${theme.colors.flamingoPink};
    a {
        display: flex;
        justify-content: center;
    }

    @media (max-height: 800px) {
        min-height: 800px;
    }
    @media (max-width: 750px) {
        gap: 125px;
    }
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    grid-gap: 30px;
    row-gap: 150px;
    flex-wrap: wrap;
    padding-bottom: 100px;
    @media (max-width: 1350px) {
        padding: 0 20%;
        padding-bottom: 25px;
    }
    @media (max-width: 750px) {
        row-gap: 120px;
    }
`

const FounderCard = styled.div`
    width: 220px;
    height: 310px;
    border: 3px solid ${theme.colors.white};
    box-shadow: 4px 4px 0px ${theme.colors.white};
    border-radius: ${theme.borderRadius.normal};
    background-color: ${theme.colors.white};
    position: relative;
    .image {
        border-radius: ${theme.borderRadius.normal};
    }
    .filter {
        position: absolute;
        top: 0;
    }
    .info {
        width: 100%;
        position: absolute;
        bottom: -100px;
        display: grid;
        gap: 10px;
        height: 75px;
        h5 {
            height: 30px;
        }
        @media (max-width: 750px) {
            gap: 0px;
            h5 {
                row-gap: 100px;
                font-size: 20px;
            }
        }
    }
    .linkedin {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 1;
    }
`

const Triangle = styled.img`
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    @media (max-width: 1050px) {
        display: none;
    }
`

const Founders = () => {
    const foundersItems = [
        {
            name: 'Benedict Kurz',
            employeetitle: 'CEO',
            image: BenedictImage,
            linkedinLink: 'https://www.linkedin.com/in/benedict-kurz-11abb1159/',
        },
        {
            name: 'Lucas Hild',
            employeetitle: 'CTO',
            image: LucasImage,
            linkedinLink: 'https://www.linkedin.com/in/lucashild/',
        },
        {
            name: 'Gregor Weber',
            employeetitle: 'Head of Mobile',
            image: GregorImage,
            linkedinLink: 'https://www.linkedin.com/in/gregor-weber-11714b204/',
        },
        {
            name: 'Julian Prigl',
            employeetitle: 'Head of Sales and Customer Support',
            image: JulianImage,
            linkedinLink: 'https://www.linkedin.com/in/julian-prigl-50697a15b/',
        },
        {
            name: 'Yannik Prigl',
            employeetitle: 'Head of Backend',
            image: YannikImage,
            linkedinLink: 'https://www.linkedin.com/in/yannik-prigl-20a9b6201/',
        },
    ]

    return (
        <Container>
            <Triangle src={TriangleBlob} alt="backtround blob" />
            <Headline2 textAlign="center" color={theme.colors.white}>
                OUR FOUNDERS
            </Headline2>
            <ImageContainer>
                {foundersItems.map((founder) => (
                    <FounderCard key={founder.name}>
                        <a href={founder.linkedinLink} target="_blank" rel="noreferrer" className="linkedin">
                            <img src={LinkedInLogo} alt="linkedin logo" />
                        </a>
                        <img src={founder.image} alt={founder.name} className="image" />
                        <img src={ImageFilter} alt="Gray filter" className="filter" />
                        <div className="info">
                            <Headline5 textAlign="center" color={theme.colors.white}>
                                {founder.name}
                            </Headline5>
                            <Body1 textAlign="center" color={theme.colors.white}>
                                {founder.employeetitle}
                            </Body1>
                        </div>
                    </FounderCard>
                ))}
            </ImageContainer>
            <DynamicButton
                width={250}
                href={URLs.CareersWebsite}
                borderRadius={theme.borderRadius.normal}
                type="white"
                color={theme.colors.white}
            >
                <ButtonText color={theme.colors.accentBlue}>Open Positions</ButtonText>
            </DynamicButton>
        </Container>
    )
}

export default Founders
