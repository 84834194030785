import React from 'react'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { Body1, Headline2, Headline4 } from 'components/elements/Text'
import YellowCircle from 'assets/homepage/yellow-circle.webp'
import EarthIcon from 'assets/culture/earth.svg'
import WritingIcon from 'assets/culture/writing.svg'
import TeamEventIcon from 'assets/culture/team_event.svg'
import RocketIcon from 'assets/culture/rocket.svg'
import EnergyBoostIcon from 'assets/culture/energy_boost.svg'
import BenefitsIcon from 'assets/culture/benefits.svg'
import Stack from 'components/elements/Stack'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import { FlipCard } from 'components/elements/FlipCard'

const Container = styled.section`
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: ${theme.colors.deepSeaBlue};
    position: relative;
    display: grid;
    padding: 50px 75px 150px 75px;
    overflow: hidden;
    .subtitle {
        padding: 0 30%;
    }
    .circle {
        position: absolute;
        left: 0;
        top: 200px;
    }
    @media (max-width: 1080px) {
        .subtitle {
            padding: 0%;
        }
    }
    @media (max-width: 750px) {
        padding: 10%;
        .circle {
            display: none;
        }
    }
`

const ValuesWrapper = styled.div`
    padding: 75px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    gap: 30px;

    @media (max-width: 1080px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`

interface Props {}

const BenefitsList = (props: Props) => {
    const md = useMedia(deviceSize.md)
    const benefitsList = [
        {
            title: '100% Flexibility',
            icon: EarthIcon,
            text: 'Work from where you want! Whether in our new office in Berlin Mitte or remotely from anywhere in Europe! It is up to you.',
        },
        {
            title: 'Workshops',
            icon: WritingIcon,
            text: 'Do you feel like getting some input? We offer you exciting workshops with great experts.',
        },
        {
            title: 'Team events',
            icon: TeamEventIcon,
            text: 'By organizing regular team events with the whole company we aim to build a strong company culture and an inspiring team spirit. These vary from sport activities to parties in our office in Berlin.',
        },
        {
            title: 'Personal Development',
            icon: RocketIcon,
            text: 'Once per quarter we offer exciting personal development courses. You can choose from singing lessons to design workshops - whatever you would like to improve in.',
        },
        {
            title: 'Energy Booster',
            icon: EnergyBoostIcon,
            text: 'Do you need a boost? We always have fresh fruits, cold drinks,coffee and snacks waiting for you. On Fridays we enjoy after work drinks together.',
        },
        {
            title: 'Monetary Benefits',
            icon: BenefitsIcon,
            text: 'We offer each deparment a quarterly event budget to give everyone the opportunity to get to know eachother. Besides that we grant each employee a membership either at Become1 or Urban Sports Club.',
        },
    ]

    //
    return (
        <Container>
            <img src={YellowCircle} alt="background circle" className="circle" />
            <Headline2 color={theme.colors.white} textAlign="center">
                Benefits
            </Headline2>
            <Body1 color={theme.colors.white} textAlign="center" className="subtitle">
                First and foremost, we want you to feel happy, included and motivated, be healthy and have the opportunity
                for personal development. In the following you can find our main benefits.
            </Body1>
            <ValuesWrapper>
                {benefitsList.map((benefit, i) => (
                    <FlipCard key={`benefit-${i}`} align={i % 2 !== 0 ? 'left' : 'right'}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <Stack gutter={md ? 50 : 0}>
                                    <img src={benefit.icon} alt={`${benefit.title} Benefit icon"`} />
                                    <Headline2 color={theme.colors.sunlightYellow} marginBottom={md ? 25 : 50}>{`0${
                                        i + 1
                                    }`}</Headline2>
                                    <div>
                                        <Headline4 color={theme.colors.accentBlue} marginBottom={md ? 15 : 25}>
                                            {benefit.title}
                                        </Headline4>
                                        <Body1 color={theme.colors.accentBlue} fontWeigth={600}>
                                            {benefit.text}
                                        </Body1>
                                    </div>
                                </Stack>
                            </div>
                            <div className="flip-card-back">
                                <Stack gutter={85}>
                                    <Headline2 color="rgba(255, 255, 255, 0.2)">{`0${i + 1}`}</Headline2>
                                    <Headline4 color="rgba(255, 255, 255, 0.6)">{benefit.title}</Headline4>
                                </Stack>
                            </div>
                        </div>
                    </FlipCard>
                ))}
            </ValuesWrapper>
        </Container>
    )
}

export default BenefitsList
