/* eslint-disable array-callback-return */
import React from 'react'
import styled, { css } from 'styled-components'
import { Body2, Headline3 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

const Container = styled.div<{ backgroundColor?: string }>`
    & {
        background-color: ${({ backgroundColor }) =>
            backgroundColor ? backgroundColor : theme.colors.darkerAccentBlue} !important;
        padding: 25px;
        @media (max-width: 1050px) {
            padding: 20px;
        }
    }
`

const Grid = styled.div<{ noImage?: boolean; textColor?: string }>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ noImage }) => `1fr 1fr ${noImage ? '' : '400px'} 1fr 1fr;`};
    place-content: center;

    a,
    img {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    @media (max-width: 1050px) {
        grid-template-columns: ${({ noImage }) => `1fr ${noImage ? '' : '300px'} 1fr;`};
        img {
            margin-top: 35px;
        }
    }
    p,
    h3 {
        color: ${({ textColor }) => textColor ?? theme.colors.white};
    }
    .tabler-spacer {
        ${({ noImage }) =>
            noImage
                ? css`
                      display: none;
                  `
                : ''}
        @media (min-width: 1051px) {
            display: none;
        }
    }
    .desktop-spacer {
        ${({ noImage }) =>
            noImage
                ? css`
                      display: none;
                  `
                : ''}
        @media (max-width: 1050px) {
            display: none;
        }
    }
    @media (max-width: 750px) {
        .tabler-spacer {
            display: none;
        }

        .desktop-spacer: {
            display: none;
        }
        padding-top: ${({ noImage }) => (noImage ? 0 : '100px')};
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        img {
            margin-top: -100px;
        }
    }
`

interface Props {
    items: {
        title: string
        subtitle: string
    }[]
    image: StaticImageData
    textColor: string
    onClickTarget: string
    backgroundColor: string
}

const renderStaticticItem = (item: Props['items'][number]) => (
    <div key={item.subtitle}>
        <Headline3 textAlign="center">{item.title}</Headline3>
        <Body2 textAlign="center">{item.subtitle}</Body2>
    </div>
)

const renderMiddleImage = (image: StaticImageData, onClickTarget: string) => (
    <>
        <a href={onClickTarget} target="_blank" rel="noreferrer">
            <img src={image?.src ?? ''} alt={image?.placeholder ?? ''} width={image?.width} height={image?.height} />
        </a>
        <div className="desktop-spacer" />
    </>
)

const renderStatisticGrid = ({ items, image, onClickTarget }: Props) => {
    return (
        <>
            {items.map((item, index) => {
                switch (index) {
                    case 0:
                    case 2:
                        return (
                            <>
                                {renderStaticticItem(item)}
                                <div className="tabler-spacer" />
                            </>
                        )
                    case 1:
                        return (
                            <>
                                {renderStaticticItem(item)}
                                {renderMiddleImage(image, onClickTarget)}
                            </>
                        )
                    case 3:
                        return renderStaticticItem(item)
                }
            })}
        </>
    )
}

const StatisticsGrid = (props: Props) => {
    return (
        <Container backgroundColor={props.backgroundColor}>
            <Grid noImage={!props.image?.src} textColor={props.textColor}>
                {renderStatisticGrid(props)}
            </Grid>
        </Container>
    )
}

export default StatisticsGrid
