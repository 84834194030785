import Navbar from 'components/modules/Navbar/Navbar'
import theme from 'lib/constants/theme'
import Home from 'pages/Home/Home'
import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import styled from 'styled-components'
import './App.css'
import Footer from 'components/modules/Footer/Footer'
import Culture from 'pages/Culture/Culture'
import routes from 'lib/constants/routes'
import ApplicationProcess from 'pages/ApplicationProcess/ApplicationProcess'
import ScrollToTop from 'components/modules/ScrollToTop'
import Team from 'pages/Team/Team'

const Container = styled.div`
    background-color: ${theme.colors.deepSeaBlue};
    border-top: 0.1px solid ${theme.colors.deepSeaBlue};
`
const Main = styled.main`
    min-height: ${`calc(100vh - ${theme.lengths.navbar}px)`};
    margin-top: ${theme.lengths.navbar}px;

    @media (max-width: 750px) {
        margin-top: 0;
    }
`

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Container>
                <Navbar />
                <Main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path={routes.cultureRoute} element={<Culture />} />
                        <Route path={routes.teamRoute} element={<Team />} />
                        <Route path={routes.applicationProcessRoute} element={<ApplicationProcess />} />
                    </Routes>
                </Main>
                <Footer />
            </Container>
        </Router>
    )
}

export default App
