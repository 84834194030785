import React, { useEffect } from 'react'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { Body2, Headline2, Headline3, QuoteText, QuoteText2, Subtitle1 } from 'components/elements/Text'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import YellowCircle from 'assets/homepage/yellow-circle.webp'
import useMedia from 'lib/hooks/useMedia'

const Container = styled.div`
    width: 100%;
    min-height: 800px;
    height: 4250px;
    background-color: ${theme.colors.blueIce};
    position: relative;
    display: grid;
    padding: 5% 0;
    .title {
        width: 100%;
        position: sticky;
    }
    @media (max-width: 1680px) {
        height: 5000px;
    }
    @media (max-width: 1440px) {
        height: 5500px;
    }
    @media (max-width: 1250px) {
        height: 6500px;
    }

    @media (max-width: 1080px) {
        overflow: hidden;
        height: auto;
        grid-template-columns: 1fr;
        display: flex;
        justify-content: center;
        padding-top: 0px;
        padding-bottom: 50px;
    }
`

const ValuesWrapper = styled.div<{ storyCheckpointsCount: number }>`
    padding: 75px 0px;

    display: grid;
    place-items: center;
    grid-template-columns: ${({ storyCheckpointsCount }) => `repeat(${storyCheckpointsCount}, 1fr)`};
    grid-gap: 125px;

    will-change: transform;
    transform: translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    @media (max-width: 1250px) {
        grid-gap: 100px;
    }
    @media (max-width: 1080px) {
        grid-gap: 0px;
        padding: 0;
        grid-template-columns: 1fr;
    }
`

const MobileWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 25px;
    margin-top: 50px;
    min-height: 800px;
`

const Card = styled.div<{ backgroundColor: string; isOdd?: boolean }>`
    display: grid;
    height: 180px;
    width: 600px;
    overflow: visible;
    background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.deepSeaBlue};
    border: 3px solid ${theme.colors.accentBlue};
    box-shadow: 4px 4px 0px ${theme.colors.accentBlue};
    border-radius: ${theme.borderRadius.huge};
    padding: 50px;
    position: relative;
    margin-top: ${({ isOdd }) => (isOdd ? 325 : -375)}px;
    h3 {
        position: absolute;
        bottom: -30px;
        left: -15px;
        text-shadow: 4px 4px 0px ${theme.colors.accentBlue};
    }
    .timeline-date {
        position: absolute;
        left: -20px;
        top: ${({ isOdd }) => (isOdd ? -65 : 245)}px;
    }
    @media (max-height: 800px) {
        margin-top: ${({ isOdd }) => (isOdd ? 250 : -400)}px;
        .timeline-date {
            top: ${({ isOdd }) => (isOdd ? -50 : 225)}px;
        }
    }
    @media (max-width: 1080px) {
        display: flex;
        width: 300px;
        height: auto;
        flex-direction: column;
        place-content: start;
        grid-template-columns: 1fr;
        align-items: center;
        padding: 25px;
        margin: 0;
        box-shadow: 2px 2px 0px ${theme.colors.accentBlue};
        .timeline-date {
            position: absolute;
            top: -28px;
            left: 0px;
        }
        .title {
            position: absolute;
            text-align: left;
            top: -28px;
            left: 140px;
            text-shadow: none;
        }
    }
`

const Timeline = styled.div`
    height: 6px;
    width: 100%;
    position: relative;
    top: 330px;
    background-color: ${theme.colors.accentBlue};
    @media (max-height: 800px) {
        top: 265px;
    }
    @media (max-width: 1080px) {
        display: none;
    }
`

const Camera = styled.div`
    @media (min-width: 1080px) {
        .circle {
            display: block;
            position: absolute;
            left: -50px;
            z-index: 1;
        }
        background-color: ${theme.colors.blueIce};
        width: 100%;
        height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 150px;
        z-index: 50;
        overflow: hidden;
    }
    @media (max-width: 1080px), (max-height: 800px) {
        .circle {
            display: none;
        }
    }
    @media (max-height: 800px) {
        top: 100px;
    }
    overflow: auto;
`

const Line = styled.div`
    position: absolute;
    border-radius: ${theme.borderRadius.huge};
    height: 93%;
    border: 6px solid ${theme.colors.accentBlue};
    width: 100%;
    left: -95%;
`

interface Props {}

const OurStorySection = (props: Props) => {
    const lg = useMedia(deviceSize.lg)

    useEffect(() => {
        const background = document.getElementById('horizontal-story-scroll')

        const listenToScroll = () => {
            if (background?.style) background.style.transform = `translate3d(${150 - window.scrollY * 0.1}vw, 0px, 0px)`
        }

        window.addEventListener('scroll', listenToScroll)
        listenToScroll()
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [])

    const ourStoryData = [
        {
            date: 'April, 2020',
            title: 'FOUNDATION',
            color: theme.colors.deepSeaBlue,
            text: 'Benedict, Lucas, Gregor, Julian and Yannik founded Knowunity',
        },
        {
            date: 'September, 2020',
            title: 'LAUNCH',
            color: theme.colors.forestGreen,
            text: 'We launched the Knowunity App',
        },
        {
            date: 'December, 2020',
            title: 'USERS',
            color: theme.colors.flamingoPink,
            text: 'More than 350.000 users downloaded the app',
        },
        {
            date: 'April, 2021',
            title: 'SEED ROUND',
            color: theme.colors.deepSeaBlue,
            text: 'We raised over 2 Million Euros in our seed round',
        },
        {
            date: 'August, 2021',
            title: 'EXPANSION',
            color: theme.colors.flamingoPink,
            text: 'We launched the platform in Austria and Switzerland',
        },
        {
            date: 'January, 2022',
            title: 'HEADQUARTERS',
            color: theme.colors.forestGreen,
            text: 'We opened our headquarters in Berlin',
        },
        {
            date: 'February, 2022',
            title: 'EXPANSION',
            color: theme.colors.deepSeaBlue,
            text: 'We launched the platform in France',
        },
        {
            date: 'April, 2022',
            title: 'EXPANSION',
            color: theme.colors.flamingoPink,
            text: 'We launched the platform in Poland and have over 2 Million active users overall. ',
        },
        // {
        //     date: 'April, 2022',
        //     title: 'SERIES A',
        //     color: theme.colors.forestGreen,
        //     text: 'We raised xx Million Euros in our Series A round.',
        // },
    ]

    return (
        <Container>
            {lg ? (
                <MobileWrapper>
                    <Line />
                    <Headline2 textAlign="center">OUR STORY</Headline2>
                    {ourStoryData.map((story, i) => (
                        <Card key={`advantage-${i}`} backgroundColor={story.color}>
                            <Body2 color={theme.colors.accentBlue} className="timeline-date" fontWeigth={700}>
                                {story.date}
                            </Body2>
                            <Headline3 color={theme.colors.accentBlue} marginBottom={20} className="title" fontWeigth={800}>
                                {story.title}
                            </Headline3>
                            <QuoteText2 textAlign="left" color={theme.colors.white} fontWeigth={600}>
                                {story.text}
                            </QuoteText2>
                        </Card>
                    ))}
                </MobileWrapper>
            ) : (
                <Camera>
                    <img src={YellowCircle} alt="yellow circle" className="circle" />

                    <Headline2 className="title" textAlign="center">
                        OUR STORY
                    </Headline2>
                    <Timeline />
                    <ValuesWrapper id="horizontal-story-scroll" storyCheckpointsCount={ourStoryData.length}>
                        {ourStoryData.map((story, i) => (
                            <Card key={`advantage-${i}`} backgroundColor={story.color} isOdd={i % 2 !== 0}>
                                <Subtitle1 className="timeline-date" color={theme.colors.accentBlue}>
                                    {story.date}
                                </Subtitle1>
                                <Headline3 color={theme.colors.white} marginBottom={10} textAlign="center">
                                    {story.title}
                                </Headline3>
                                <QuoteText textAlign="left" color={theme.colors.white} fontWeigth={600}>
                                    {story.text}
                                </QuoteText>
                            </Card>
                        ))}
                    </ValuesWrapper>
                </Camera>
            )}
        </Container>
    )
}

export default OurStorySection
