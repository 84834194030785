import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import theme from 'lib/constants/theme'
import { Link } from 'react-router-dom'

interface Props {
    type?: 'primary' | 'secondary' | 'default' | 'white'
    borderRadius?: string
    rounded?: boolean
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    children?: ReactNode
    icon?: JSX.Element
    loadingArea?: string
    disabled?: boolean
    fullWidth?: boolean
    height?: number
    width?: number
    fixedBottom?: boolean
    color?: string
    disableFullWidth?: boolean
    buttonType?: 'button' | 'submit'
}

const Container = styled.div<{
    backgroundColor: string
    color: string
    backgroundHoverColor: string
    hasIcon: boolean
    borderColor: string | null
    borderRadius?: string
    fullWidth: boolean
    disabled: boolean
    height?: number
    width?: number
}>`
    background-color: ${(props) => props.backgroundColor};
    border-radius: ${(props) => props.borderRadius};
    color: ${(props) => props.color};
    cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
    border: ${(props) => (props.borderColor ? '2px solid ' + props.borderColor : '2px solid ' + props.backgroundColor)};
    padding: 15px;
    width: ${(props) => (props?.width ? `${props.width}px` : props.fullWidth ? '100%' : 'auto')};
    height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: ${(props) => (props.hasIcon ? 'auto auto' : 'auto')};
    grid-gap: ${(props) => (props.hasIcon ? '10px' : '0px')};
    position: relative;
    transition: all 0.25s ease;

    &:hover {
        will-change: transform;
        transform: translate(-4px, -4px);
        animation: hover 0.25s 1 forwards;

        ${({ backgroundColor }) =>
            backgroundColor === theme.colors.white
                ? css`
                      box-shadow: 4px 4px 0px ${backgroundColor};
                      border: 2px solid ${theme.colors.accentBlue};
                  `
                : css`
                      border: 2px solid ${theme.colors.white};
                      box-shadow: 4px 4px 0px ${backgroundColor};
                  `}
    }

    & svg {
        fill: ${(props) => props.color};
        max-width: 26px;
    }
    @media (max-width: 900px) {
        width: 100%;
    }
`

const NewButtonContainer = (props: Props) => {
    const type = props.type || 'primary'

    let backgroundColor = type === 'primary' ? 'var(--secondary-color)' : theme.colors.white
    let color = type === 'primary' ? 'white' : type === 'default' ? 'var(--text-light-gray)' : 'var(--secondary-color)'
    let backgroundHoverColor = type === 'primary' ? 'var(--secondary-color-dark)' : 'none'
    let borderColor = type === 'primary' ? null : type === 'default' ? 'var(--text-light-gray)' : 'var(--secondary-color)'
    const borderRadius = props.rounded ? theme.borderRadius.huge : props.borderRadius ?? theme.borderRadius.smallest

    if (type === 'white') {
        backgroundColor = 'transparent'
        backgroundHoverColor = theme.colors.white
        borderColor = theme.colors.white
        color = 'transparent'
    }
    if (props.disabled) {
        backgroundColor = 'var(--gray)'
        backgroundHoverColor = 'var(--gray)'
        if (type === 'secondary') {
            borderColor = 'var(--gray)'
            color = 'var(--gray)'
            backgroundColor = 'white'
            backgroundHoverColor = 'white'
        }
    }
    if (props.color) {
        backgroundColor = props.type === 'secondary' ? theme.colors.white : props.color
        backgroundHoverColor = props.type === 'secondary' ? theme.colors.white : props.color
        borderColor = props.color
    }

    return (
        <Container
            backgroundColor={backgroundColor}
            color={color}
            backgroundHoverColor={backgroundHoverColor}
            hasIcon={!!props.icon}
            borderColor={borderColor}
            borderRadius={borderRadius}
            fullWidth={!!props.fullWidth}
            disabled={!!props.disabled}
            height={props.height}
            width={props.width}
        >
            <>
                {props.icon}
                {props.children}
            </>
        </Container>
    )
}

interface ButtonElementProps {
    fixedBottom?: boolean
    disableFullWidth?: boolean
}
const ButtonElement = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonElementProps>`
    background: transparent;
    border: 0;
    padding: 0;
    width: ${({ disableFullWidth }) => (disableFullWidth ? 'auto' : '100%')};
    ${({ fixedBottom }) =>
        fixedBottom ? `position: fixed; bottom: 25px; left: 50%; transform: translateX(-50%); padding: 0 25px; ` : ''};
`

const NewButton = (props: Props) => (
    <ButtonElement
        type={props.buttonType ? props.buttonType : 'submit'}
        onClick={
            props.disabled
                ? () => {
                      /* do nothing */
                  }
                : props.onClick
        }
        disableFullWidth={props.disableFullWidth}
        fixedBottom={props.fixedBottom}
    >
        <NewButtonContainer {...props} />
    </ButtonElement>
)

const AElement = styled.a`
    display: inline-block;
`

export interface NewButtonAProps extends Props {
    href: string
    target?: '_blank'
}

export const NewButtonA = (props: NewButtonAProps) => (
    <AElement href={props.href} target={props.target}>
        <NewButtonContainer {...props} />
    </AElement>
)

const LinkElement = styled(Link)`
    display: block;
`

export interface NewButtonLinkProps extends Props {
    href: string
    rel?: string
}

export const NewButtonLink = (props: NewButtonLinkProps) => (
    <LinkElement to={props.href}>
        <NewButtonContainer {...props} onClick={props.onClick} />
    </LinkElement>
)

export default NewButton
