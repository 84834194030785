import React, { ReactNode } from 'react'
import { NewButtonAProps, NewButtonLinkProps, NewButtonA, NewButtonLink } from './Button'

interface Props extends NewButtonAProps, NewButtonLinkProps {
    children: ReactNode
}

const DynamicButton = ({ children, ...buttonProps }: Props) => {
    const { href } = buttonProps

    const isExternalLink = href?.startsWith('https://') ?? false
    return (
        <>
            {isExternalLink ? (
                <NewButtonA target="_blank" {...buttonProps}>
                    {children}
                </NewButtonA>
            ) : (
                <NewButtonLink {...buttonProps}>{children}</NewButtonLink>
            )}
        </>
    )
}

export default DynamicButton
