import ComponentContainer from 'components/elements/ComponentContainer'
import { Headline2, Body1 } from 'components/elements/Text'
import TransparentAccordion from 'components/elements/TransparentAccordion'
import theme from 'lib/constants/theme'

const CommandmentsList = () => {
    const faqQuestions = [
        {
            question: 'Students always first',
            answer: 'Everything we do should ultimately support  our users.',
        },
        {
            question: 'Focus on building the global platform for students',
            answer: 'Our constant goal is to build the #1 Platform for students.Every decision we take should bring us one step closer to achieving that goal.',
        },
        {
            question: 'Hands on mentality',
            answer: 'We should all embrace assertiveness, action orientation and determination to achieve our goals.',
        },
        {
            question: 'Speed beats perfection',
            answer: `Getting hung up on things and waiting until they are perfect is not in our nature. Of course, we have to be careful that the quality doesn't suffer, but our primary focus is on completing our task to reach our goals faster.`,
        },
        {
            question: 'Fail fast - fail early',
            answer: `Making mistakes is not a problem at all - we all make some. We believe it's better to make many mistakes at the beginning, rather than big ones at the end. Small mistakes are always possible - don't worry!`,
        },
        {
            question: 'Education needs quality - 4 eyes principle',
            answer: 'Everything that is implemented or set up, should go through at least one other employee. Whether supervisor or co-worker - four eyes catch more mistakes than two (and provide valuable perspective).',
        },
        {
            question: 'Always learning - every day 1% better',
            answer: 'We should all put it on our personal agenda to get a little better every day. Because only the person who is curious and has the will to learn can create great things.',
        },
        {
            question: 'Critical thinking',
            answer: 'Nothing is perfect. Mistakes can always happen - Be attentive! Be willing to question the status quo every day! Can something be done better? Can something be done even more agilely?',
        },
        {
            question: 'Accept diversity - honest & open feedback culture',
            answer: 'We all come from different backgrounds. We all have different opinions. Be open! Accept other decisions! Find solutions together with your colleagues.',
        },
        {
            question: 'Respect',
            answer: 'We do not tolerate behavior that makes our co-workers feel uncomfortable. We are all one family! That means that things like racism and similar things have no place at Knowunity! Show each other the respect that you expect for yourselves and create a great working environment.',
        },
    ]

    return (
        <ComponentContainer color={theme.colors.deepSeaBlue}>
            <Headline2 color={theme.colors.white} textAlign="center" marginBottom={0}>
                10 commandments
            </Headline2>
            <section>
                <Body1 textAlign="center" marginBottom={50} color={theme.colors.mediumWhite}>
                    {`We want you to feel good from day one on. Therefore we have created 10 commandments which we follow on a
                    daily basis and that show you what's important for our daily work and what our people values are.`}
                </Body1>
                {faqQuestions.map((item, i) => (
                    <TransparentAccordion
                        key={`commandment-${i}`}
                        accordionKey={(++i).toString().padStart(2, '0')}
                        text={
                            <Body1 color={theme.colors.mediumWhite} textAlign="center">
                                {item.answer}
                            </Body1>
                        }
                        title={item.question}
                        noPadding
                        accentBlue
                    />
                ))}
            </section>
        </ComponentContainer>
    )
}

export default CommandmentsList
