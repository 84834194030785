import { useCallback, useState } from 'react'

type UseToggleProps = [boolean, () => void]

export const useToggleState = (initState?: boolean): UseToggleProps => {
    const [value, setValue] = useState(initState || false)

    const toggleValue = useCallback(() => {
        setValue(!value)
    }, [value])

    return [value, toggleValue]
}
