import { TeamMember, TeamNames } from 'interfaces/TeamMember'
import theme from 'lib/constants/theme'
import { useToggleState } from 'lib/hooks/useToggleState'
import styled from 'styled-components'
import { Headline3, Subtitle1 } from './Text'

const Card = styled.div<{ hoverColor: string }>`
    width: 194px;
    height: 365px;
    background: ${theme.colors.white};
    border: 2px solid ${theme.colors.accentBlue};
    border-radius: ${theme.borderRadius.large};
    padding: 8px;
    display: grid;
    place-content: start center;
    gap: 10px;
    justify-self: center;
    h5 {
        display: grid;
        place-content: center;
        height: 70px;
    }
    .image-card {
        border-radius: 15px;
        background-color: ${`${theme.colors.blueIce}44`};

        width: 179px;
        height: 268px;
        transition: all 0.5s ease;
        position: relative;
        h3 {
            position: relative;
            z-index: 0;
            left: 7.5px;
            top: 5px;
            font-size: 32px;
            line-height: 36px;
            width: 175px;
            word-break: break-all;
            opacity: 0;
        }
        :hover {
            background-color: ${({ hoverColor }) => `${hoverColor}55`};
            h3 {
                opacity: 1;
                color: ${theme.colors.accentBlue};
            }
        }
        img {
            z-index: 1;
            position: absolute;
            border-radius: 15px;
            bottom: 0px;
            object-fit: scale-down;
            object-position: bottom;
        }
    }
`

const returnBackgroundColor = (team: TeamNames) => {
    switch (team) {
        case TeamNames.BusinessDevelopment:
        case TeamNames.Expansion:
            return theme.colors.sunlightYellow
        case TeamNames.Engineering:
        case TeamNames.Data:
            return theme.colors.forestGreen
        case TeamNames.HR:
        case TeamNames.Finance:
            return theme.colors.knowunityBlue
        case TeamNames.Product:
        case TeamNames.Founders:
            return theme.colors.deepSeaBlue
        case TeamNames.SalesCustomerSupport:
        case TeamNames.Growth:
            return theme.colors.accentBlue
        case TeamNames.Marketing:
            return theme.colors.flamingoPink
        default:
            return theme.colors.blueIce
    }
}

interface Props {
    member: TeamMember
}

const TeamMemberCard = ({ member }: Props) => {
    const [hovering, toggleHovering] = useToggleState(false)
    return (
        <Card hoverColor={returnBackgroundColor(member.team)} onMouseEnter={toggleHovering} onMouseLeave={toggleHovering}>
            <div className="image-card">
                <Headline3>{member.team}</Headline3>
                <img src={member.image.url} width={179} height={268} alt={`${member.full_name} headshot`} />
            </div>
            <Subtitle1 color={theme.colors.accentBlue} textAlign="center">
                {hovering ? member.job_title : member.full_name}
            </Subtitle1>
        </Card>
    )
}
export default TeamMemberCard
