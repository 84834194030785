import Header from 'components/modules/LandingPage/Header'
import Meta from 'components/modules/Meta'
import theme from 'lib/constants/theme'
import { URLs } from 'lib/constants/URLs'
import HeroBackground from 'assets/application-process/application-hero.webp'
import ApplyNowBanner from 'components/modules/LandingPage/ApplyNowBanner'
import ApplicationProcessList from 'components/modules/LandingPage/ApplicationProcessList'
import ComponentContainer from 'components/elements/ComponentContainer'
import CollabsibleAccordion from 'components/elements/CollapsibleAccordion'
import { Headline2, Body1 } from 'components/elements/Text'

const ApplicationProcess = () => {
    const faqQuestions = [
        {
            question: 'Can I apply to multiple openings',
            answer: 'Sure! But please apply to relevant roles.',
        },
        {
            question: `If I don't get an offer, can I apply for another role?`,
            answer: 'Always. We value grit & tenacity.',
        },
        {
            question: 'How long will it take to receive feedback?',
            answer: 'We aim to give feedback under 5-7 days.',
        },
    ]

    return (
        <div>
            <Meta
                title="Application process in Knowunity"
                description="Check out how does applying to work in leading e-learning startup looks like!"
            />
            <Header
                title="From applying to the next step in your career - What you can expect:"
                text="Let’s make your application at Knowunity an engaging and conversational experience. We are excited to get to know you!"
                buttonText="Open positions"
                buttonTarget={URLs.CareersWebsite}
                buttonColor={theme.colors.flamingoPink}
                buttonTextColor={theme.colors.white}
                backgroundColor={theme.colors.deepSeaBlue}
                fullWidth
                isWide
                image={{
                    src: HeroBackground,
                    height: 0,
                    width: 0,
                    placeholder: undefined,
                }}
                hideBottomStatistics
            />
            <ApplicationProcessList />
            <ComponentContainer color={theme.colors.blueIce}>
                <section>
                    <Headline2 color={theme.colors.accentBlue} textAlign="center" marginBottom={50}>
                        We thought you would never ask
                    </Headline2>
                    {faqQuestions.map((item, i) => (
                        <CollabsibleAccordion
                            key={`faq-question-${i}`}
                            text={<Body1 color={theme.colors.accentBlue}>{item.answer}</Body1>}
                            title={item.question}
                            accentBlue
                        />
                    ))}
                </section>
            </ComponentContainer>
            <ApplyNowBanner />
        </div>
    )
}

export default ApplicationProcess
