/* eslint-disable jsx-a11y/anchor-is-valid */
import styled, { css } from 'styled-components'
import MenuIcon from 'assets/icons/menu.svg'
import { Subtitle1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { useCallback, useEffect, useRef } from 'react'
import routes from 'lib/constants/routes'
import { NavLink } from 'react-router-dom'

interface Props {
    open: boolean
    handleClose: () => void
}

const MobileMenu = styled.div<{ readonly show: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: visible;
    background: ${theme.colors.deepSeaBlue};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    display: none;
    z-index: ${theme.zIndex.modal};
    & .mobile-expand-icon {
        position: absolute;
        fill: white;
        top: 22.5px;
        left: 21px;
        z-index: ${theme.zIndex.FAB};
    }

    & ul {
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        gap: 15px !important;
    }

    ${(props) =>
        props.show &&
        css`
            display: initial;
        `}
`

const MobileNavbarMenu = ({ open, handleClose }: Props) => {
    const menuRef = useRef<HTMLDivElement>(null)

    const handleMouseDown = useCallback(
        (event: MouseEvent) => {
            const isClickOutside = !menuRef.current?.contains(event.target as Node)
            if (isClickOutside) {
                open && handleClose()
            }
        },
        [handleClose, open]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [handleMouseDown])

    return (
        <MobileMenu show={open} ref={menuRef}>
            <img src={MenuIcon} onClick={handleClose} className="mobile-expand-icon" alt="expand menu icon" />
            <ul>
                <li>
                    <NavLink to={routes.homepageRoute} onClick={handleClose}>
                        <Subtitle1 color={theme.colors.white}>Start</Subtitle1>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={routes.cultureRoute} onClick={handleClose}>
                        <Subtitle1 color={theme.colors.white}>Culture &amp; Diversity</Subtitle1>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={routes.applicationProcessRoute} onClick={handleClose}>
                        <Subtitle1 color={theme.colors.white}>Application Process</Subtitle1>
                    </NavLink>
                </li>
            </ul>
        </MobileMenu>
    )
}

export default MobileNavbarMenu
