import styled from 'styled-components'
import { Headline2 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { useEffect, useState } from 'react'
import PrismicService from 'services/PrismicService'
import { TeamMember } from 'interfaces/TeamMember'
import TeamMemberCard from 'components/elements/TeamMemberCard'

const Container = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    gap: 70px;
    place-content: start center;
    padding: 75px 25px;
    position: relative;
    background-color: ${theme.colors.blueIce};

    .members {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;
        padding-bottom: 50px;
        @media (max-width: 1050px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 750px) {
            grid-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
        }
        // iPhone XR is 414px
        @media (max-width: 413px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    @media (max-width: 1080px) {
        min-height: 550px;
        place-content: start center;
        gap: 50px;
    }
`

const TeamMembers = () => {
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>()

    useEffect(() => {
        PrismicService.getTeamMembers()
            .then((document) => setTeamMembers(document.data.member))
            .catch((error) => {
                console.error(error)
            })
    }, [])

    return (
        <Container>
            <Headline2 textAlign="center" color={theme.colors.accentBlue}>
                ALL EYES ON US
            </Headline2>
            <div className="members">
                {teamMembers?.length &&
                    teamMembers.map((member) => (
                        <TeamMemberCard member={member} key={`employee-${member.full_name}-${Math.random()}`} />
                    ))}
            </div>
        </Container>
    )
}

export default TeamMembers
