import { ButtonText, Overline } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import React from 'react'
import styled, { css } from 'styled-components'
import GooglePlayIcon from 'assets/icons/google-play.svg'
import AppleAppStoreIcon from 'assets/icons/apple-app-store.svg'
import { appDownloadUrl } from 'lib/constants/URLs'

const Container = styled.div<{ backgroundColor?: string }>`
    background: ${theme.colors.white};
    display: flex;
    justify-content: center;
    border-radius: ${theme.borderRadius.normal};
    padding: 5px 25px;
    place-items: center;
    grid-gap: 15px;
    cursor: pointer;
    min-height: 45px;
    min-width: 185px;
    & svg {
        height: 25px;
    }
    .text {
        min-width: 90px;
    }
    ${({ backgroundColor }) =>
        backgroundColor
            ? css`
                  background-color: ${backgroundColor};
              `
            : ''};
`

interface Props {
    type: 'android' | 'ios'
    onClick?: () => void
    sourceCampaign?: string
    backgroundColor?: string
}

const DownloadButton = ({ type, onClick, backgroundColor, sourceCampaign }: Props) => {
    const isAndroid = type === 'android'
    const store = isAndroid ? 'Google Play' : 'App Store'

    const onButtonClick = () => {
        onClick && onClick()
    }
    const defaultSource = 'website'
    const defaultSourceCampaign = sourceCampaign ?? 'download'

    return (
        <a href={appDownloadUrl(isAndroid ? 'android' : 'ios', defaultSource, defaultSourceCampaign)}>
            <Container onClick={onButtonClick} className="button" backgroundColor={backgroundColor}>
                {isAndroid ? (
                    <img src={GooglePlayIcon} alt="Google Play icon" />
                ) : (
                    <img src={AppleAppStoreIcon} alt="Apple App Store icon" />
                )}
                <div className="text">
                    <Overline color={theme.colors.accentBlue}>Download in</Overline>
                    <ButtonText color={theme.colors.accentBlue}>{store}</ButtonText>
                </div>
            </Container>
        </a>
    )
}

export default DownloadButton
