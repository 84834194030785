import * as Prismic from '@prismicio/client'
import { PrismicTeamMemberDoc } from 'interfaces/TeamMember'

const apiEndpoint = 'https://knowunity.cdn.prismic.io/api/v2'
const accessToken = 'MC5ZSlVHR3hJQUFDUUFqNm5F.Vu-_vQVXAGHvv717Ie-_ve-_vWRn77-9QHNS77-977-977-9LwZv77-9G2h677-9Qjvvv70f'

const teamMembersPageType = 'team-member'

export const PrismicClient = Prismic.createClient(apiEndpoint, { accessToken })

const prismicLanguage = 'en-gb'

const PrismicService = {
    getTeamMembers() {
        return PrismicClient.getSingle(teamMembersPageType, {
            lang: prismicLanguage,
        }) as unknown as Promise<PrismicTeamMemberDoc>
    },
}

export default PrismicService
