import ComponentContainer from 'components/elements/ComponentContainer'
import DynamicButton from 'components/elements/DynamicButton'
import { Body1, ButtonText, Headline3, Headline4, Subtitle1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import MobileQuoteIcon from 'assets/homepage/mobile_quote.svg'
import QuoteIcon from 'assets/homepage/quote.svg'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'

const TextWrapper = styled.section<{ backgroundColor?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 10% 15%;
    height: 100vh;
    gap: 30px;
    position: relative;
    h3 {
        font-size: 64px;
        font-weight: 900;
        line-height: 72px;
        width: 80%;
        color: ${theme.colors.white};
    }
    h5,
    h4,
    .text {
        color: ${theme.colors.white};
        width: 50%;
    }
    h5 {
        position: relative;
    }
    .credential {
        position: relative;
        top: -20px;
    }
    @media (max-width: 1250px) {
        padding: 10% 5%;
        height: auto;
        min-height: 800px;
        h3,
        h4,
        h5,
        .text {
            width: 100%;
            text-align: center;
        }
        a {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    @media (max-width: 750px) {
        padding: 20px;
        justify-content: start;
        min-height: auto;
        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
        }
    }
`

const Quote = styled.img`
    position: absolute;
    left: -50px;
    top: -15px;
    @media (max-width: 900px) {
        left: 0;
        top: 0;
    }
`

interface Props {
    title: string
    subtitle: string
    text: string
    backgroundColor?: string
    buttonTarget: string
    buttonText: string
    sectionTitle?: string
    quoteCredential?: string
    addQuoteIcon?: boolean
}
const InformationSection = ({
    title,
    subtitle,
    text,
    backgroundColor,
    buttonTarget,
    buttonText,
    sectionTitle,
    quoteCredential,
    addQuoteIcon,
}: Props) => {
    const md = useMedia(deviceSize.md)
    return (
        <ComponentContainer color={backgroundColor ?? theme.colors.deepSeaBlue}>
            <TextWrapper>
                <Subtitle1>
                    {addQuoteIcon ? <Quote src={md ? MobileQuoteIcon : QuoteIcon} alt="quote icon" /> : null}
                    {sectionTitle}
                </Subtitle1>
                <Headline3 marginBottom={25}>{title}</Headline3>
                <Headline4>{subtitle}</Headline4>
                <Body1 className="text" marginBottom={quoteCredential ? 0 : 20}>
                    {text}
                </Body1>
                {quoteCredential ? (
                    <Subtitle1 className="credential" textAlign="right">
                        {quoteCredential}
                    </Subtitle1>
                ) : null}
                <DynamicButton
                    href={buttonTarget}
                    color={theme.colors.white}
                    borderRadius={theme.borderRadius.normal}
                    width={230}
                    height={60}
                >
                    <ButtonText color={theme.colors.accentBlue}>{buttonText}</ButtonText>
                </DynamicButton>
            </TextWrapper>
        </ComponentContainer>
    )
}

export default InformationSection
