import theme from 'lib/constants/theme'
import { ReactNode } from 'react'
import styled from 'styled-components'

const StyledFlipCard = styled.div<{ align: 'left' | 'right' }>`
    justify-self: ${({ align }) => (align === 'left' ? 'flex-start' : 'flex-end')};
    display: grid;
    height: 350px;
    width: 430px;
    position: relative;
    perspective: 1000px;
    .flip-card-inner {
        border-radius: ${theme.borderRadius.normal};
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        border: 1px solid ${theme.colors.white};
    }
    &:hover .flip-card-inner {
        transform: rotateY(180deg);
    }
    .flip-card-front,
    .flip-card-back {
        padding: 32px;
        border-radius: 13px;
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    /* Style the front side (fallback if image is missing) */
    .flip-card-front {
        background-color: ${theme.colors.white} !important;
        transform: rotateY(180deg);
        img {
            position: absolute;
            right: 30px;
            top: 30px;
        }
    }

    /* Style the back side */
    .flip-card-back {
        background-color: ${theme.colors.deepSeaBlue};
    }
    @media (max-width: 1080px) {
        justify-self: center;
    }
    @media (max-width: 900px) {
        width: 100%;
        min-height: 375px;
    }
`

interface Props {
    children: ReactNode
    align: 'left' | 'right'
}

export const FlipCard = ({ children, align }: Props) => <StyledFlipCard align={align}>{children}</StyledFlipCard>
