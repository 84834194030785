import { Headline3 } from 'components/elements/Text'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import theme from 'lib/constants/theme'
import useMedia from 'lib/hooks/useMedia'
import styled from 'styled-components'

const HeaderBackground = styled.div<{ backgroundColor?: string; fullWidth?: boolean }>`
    min-height: calc(80vh + var(--navbar-height));
    height: ${({ fullWidth }) => (fullWidth ? '80vh' : 'auto')};
    display: grid;
    align-items: center;

    background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.white};
    background-size: none;
    background-position: right bottom;
    background-repeat: no-repeat;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    padding: 0 10%;
    h3 {
        width: 100%;
    }
    @media (max-width: 1680px) {
        h3 {
            width: 70%;
        }
    }
    @media (max-width: 1440px) {
        h3 {
            width: 60%;
        }
    }
    @media (max-width: 1080px) {
        display: flex;
        align-items: start;
        padding: 75px 0;
        h3 {
            width: 70%;
            text-align: center;
        }
        height: auto;
        min-height: 800px;
        background-position: center;
    }
    @media (max-height: 800px) {
        height: 700px;
    }
`

interface Props {
    title: string
    image: StaticImageData
    backgroundColor: string
    fullWidth?: boolean
    mobileImage: StaticImageData
}

const ImageBackgroundSection = ({ title, image, fullWidth, backgroundColor, mobileImage }: Props) => {
    const lg = useMedia(deviceSize.lg)
    return (
        <HeaderBackground
            style={{
                backgroundImage: fullWidth ? `url(${lg ? mobileImage.src : image.src ?? ''})` : '',
            }}
            backgroundColor={backgroundColor}
            fullWidth={fullWidth}
        >
            <Headline3>{title}</Headline3>
        </HeaderBackground>
    )
}

export default ImageBackgroundSection
