import styled from 'styled-components'
import theme from 'lib/constants/theme'

export const Container = styled.footer`
    width: 100%;
    min-height: 400px;
    background: ${theme.colors.deepSeaBlue};
    padding: 20px 5%;
    display: grid;
    grid-gap: 15px;
    z-index: 9999; //${theme.zIndex.navBar};
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr 2fr 2fr 2fr;

    @media (max-width: 1150px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 750px) {
        display: block;
    }
`

export const FirstColumn = styled.div`
    position: relative;
    min-height: 350px;
    & .title {
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    & .slogan {
        font-size: 20px;
        margin-top: 0;
    }
    @media (max-width: 1150px) {
    }
`

export const LinkColumn = styled.div`
    & ul {
        list-style-type: none;
        padding: 0;
    }

    & li {
        margin: 5px 0;
    }

    & a {
        color: ${theme.colors.white};
        opacity: 0.5;
        font-size: 14px;
        line-height: 21px;
        :hover {
            color: ${theme.colors.lightCaption};
        }
    }

    & strong {
        color: ${theme.colors.white};
    }
    @media (max-width: 750px) {
        & a {
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
        }
        & strong {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
        }
    }
`

export const DownloadGrid = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 375px;
    position: relative;
    top: 200px;
    left: 50px;
    gap: 20px;
    & a {
        width: 100%;
        max-width: 175px;
    }
    @media (max-width: 750px) {
        top: 125px;
        width: 100%;
        left: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
    @media (max-width: 450px) {
        gap: 10px;
        top: 125px;
        width: 100%;
        display: grid;
    }
`

export const CopyrightGrid = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 1px solid ${theme.colors.lightCaption};

    h6 {
        color: ${theme.colors.white};
    }
    @media (max-width: 1150px) {
        flex-wrap: wrap;
    }
`

export const FooterFrameComponent = styled.div`
    position: absolute;
    top: 25px;

    @media (max-width: 350px) {
        img {
            width: 100%;
            height: auto;
        }
    }
`

export const SocialIcons = styled.div`
    display: flex;
    gap: 10px;
    & svg {
        fill: #949494;
        width: 25px;
        height: 25px;
    }
`
