import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// React Router v5 doesn't provide scroll change out-of-the-box
// https://v5.reactrouter.com/web/guides/scroll-restoration
export default function ScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}
