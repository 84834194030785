import Header from 'components/modules/LandingPage/Header'
import Meta from 'components/modules/Meta'
import theme from 'lib/constants/theme'
import { URLs } from 'lib/constants/URLs'
import HeroBackground from 'assets/team/team-hero.webp'
import ApplyNowBanner from 'components/modules/LandingPage/ApplyNowBanner'
import CommandmentsList from 'components/modules/LandingPage/CommandmentsList'
import TeamMembers from 'components/modules/LandingPage/TeamMembers'

const Team = () => {
    return (
        <div>
            <Meta
                title="Team - Knowunity"
                description="Get to know the employees behind Knowunity! Want to be a part of a young team? Apply now!"
            />
            <Header
                title="Bringing the future of education to everyone"
                text="We are a motivated, welcoming and young team with a 100% open and transparent team culture. Discover more about the people building the leading learning platform."
                buttonText="Open positions"
                buttonTarget={URLs.CareersWebsite}
                buttonColor={theme.colors.flamingoPink}
                buttonTextColor={theme.colors.white}
                backgroundColor={theme.colors.deepSeaBlue}
                fullWidth
                isWide
                image={{
                    src: HeroBackground,
                    height: 0,
                    width: 0,
                    placeholder: undefined,
                }}
                hideBottomStatistics
            />
            <CommandmentsList />
            <TeamMembers />
            <ApplyNowBanner />
        </div>
    )
}

export default Team
