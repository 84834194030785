import React from 'react'
import InstagramIcon from 'assets/footer/instagram.svg'
import TikTokIcon from 'assets/footer/tiktok.svg'
import LinkedInIcon from 'assets/footer/linkedin.svg'
import FooterFrame from 'assets/footer/footer-frame.webp'
import { Subtitle2 } from 'components/elements/Text'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import routes, { navigationItems } from 'lib/constants/routes'
import {
    Container,
    Grid,
    FirstColumn,
    LinkColumn,
    DownloadGrid,
    CopyrightGrid,
    FooterFrameComponent,
    SocialIcons,
} from './Footer.css'
import { URLs } from 'lib/constants/URLs'
import { Link } from 'react-router-dom'
import DownloadButton from 'components/elements/DownloadBanner'

const Footer = () => {
    const md = useMedia(deviceSize.md)

    return (
        <Container>
            <Grid>
                <FirstColumn>
                    <FooterFrameComponent>
                        {md ? (
                            <img src={FooterFrame} alt="Mobile footer frame" width={280} height={200} loading="lazy" />
                        ) : (
                            <img src={FooterFrame} height={259} width={367} alt="Knowunity motto frame" loading="lazy" />
                        )}
                    </FooterFrameComponent>
                    <DownloadGrid>
                        <DownloadButton type="android" sourceCampaign="footer" />
                        <DownloadButton type="ios" sourceCampaign="footer" />
                    </DownloadGrid>
                </FirstColumn>
                <div />

                <LinkColumn>
                    <p>
                        <strong>Knowunity</strong>
                    </p>
                    <ul>
                        <li>
                            <Link to={routes.homepageRoute}>Start</Link>
                        </li>
                        {navigationItems.map((item) => (
                            <li key={`nav-link-${item.route.replace('/', '')}`}>
                                <Link to={item.route}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                </LinkColumn>
            </Grid>
            <CopyrightGrid>
                <Subtitle2>© 2022 - Knowunity</Subtitle2>
                <SocialIcons>
                    <a href={URLs.Instagram} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                        <img src={InstagramIcon} alt="Instagram Icon" />
                    </a>
                    <a href={URLs.LinkedIn} aria-label="Linkedin" target="_blank" rel="noopener noreferrer">
                        <img src={LinkedInIcon} alt="LinkedIn Icon" />
                    </a>
                    <a href={URLs.TikTok} aria-label="TikTok" target="_blank" rel="noopener noreferrer">
                        <img src={TikTokIcon} alt="TikTok Icon" />
                    </a>
                </SocialIcons>
                <Subtitle2>All rights reserved</Subtitle2>
            </CopyrightGrid>
        </Container>
    )
}

export default Footer
