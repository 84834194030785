const homepageRoute = '/'
const cultureRoute = '/culture'
const teamRoute = '/team'
const applicationProcessRoute = '/application-process'

const routes = {
    homepageRoute,
    cultureRoute,
    teamRoute,
    applicationProcessRoute,
}

export const navigationItems = [
    {
        label: 'Application Process',
        route: applicationProcessRoute,
    },
    {
        label: 'Team',
        route: teamRoute,
    },
    {
        label: 'Culture',
        route: cultureRoute,
    },
]

export default routes
